import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Outward.css"

function AddOutward() {
  const alert = useAlert();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [returnType, setReturnType] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [department, setDepartment] = useState(1);
  const [returnTypes, setReturnTypes] = useState([]);
  const [newCustomer, setNewCustomer] = useState({ newCustomerName: "", newCustomerEmail: "", newCustomerMobile: "" })
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productSku: "", productName: "", brandName: "", categoryName: "", categoryId: "", brandId: "", isWarranty: 0, products: [], items: [{ serialNumber: "", issue: "" }] }])
  const [outward, setOutward] = useState({});
  const [outwardItems, setOutwardItems] = useState([]);
  const [inwardNo, setInwardNo] = useState('');
  const [users, setUsers] = useState([]);
  const [handoverBy, setHandoverBy] = useState('');
  const [handoverTo, setHandoverTo] = useState('');
  const [vehicle, setVehicle] = useState('');



  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name == 'productQty') {
      let itemArray = [];
      for (let i = 1; i <= e.target.value; i++) {
        itemArray.push({ serialNumber: "", issue: "" });
      }
      newFormValues[i].items = itemArray;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log(formValues);
  }

  let handleChangeProducts = (i, datas) => {
    let newFormValues = [...formValues];
    newFormValues[i].products = datas;
    setFormValues(newFormValues);
  }

  let handleChangeCustomer = (e) => {
    let newItemValues = { ...newCustomer }
    newItemValues[e.target.name] = e.target.value;
    setNewCustomer(newItemValues);
  }

  let handleChangeItems = (i, j, e) => {
    let newItemValues = [...formValues];
    newItemValues[i].items[j][e.target.name] = e.target.value;
    setFormValues(newItemValues);
  }

  let handleChangeSelect = (i, e) => {
    axios("Xtras/product_detail?product_id=" + e, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let newItemValues = [...formValues];
          newItemValues[i]['productId'] = e;
          newItemValues[i]['brandName'] = response.data.product.brandName;
          newItemValues[i]['categoryName'] = response.data.product.categoryName;
          newItemValues[i]['brandId'] = response.data.product.brandId;
          newItemValues[i]['categoryId'] = response.data.product.categoryId;
          newItemValues[i]['productSku'] = response.data.product.productSku;
          newItemValues[i]['productName'] = response.data.product.name;
          setFormValues(newItemValues);
        } else {
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        alert.show(err.response.data.error);
      });

  }




  let addFormFields = () => {
    setFormValues([...formValues, { productId: "", productQty: "1", productName: "", productSku: "", brandName: "", categoryId: "", brandId: "", categoryName: "", isWarranty: 0, products: [], items: [{ serialNumber: "", issue: "" }] }
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...outwardItems];
    newFormValues.splice(i, 1);
    setOutwardItems(newFormValues)
  }

  let removeFormItemFields = (i, j) => {
    let newFormValues = [...outwardItems];
    newFormValues[i].item_products.splice(j, 1);
    newFormValues[i].qty = newFormValues[i].qty - 1;
    setOutwardItems(newFormValues)
  }

  // let handleSubmit = (event) => {
  //     event.preventDefault();
  //     alert(JSON.stringify(formValues));
  // }


  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(outwardItems));
    formdata.append("handover_to", handoverTo);
    formdata.append("department", department);
    formdata.append("inward_id", outward.id);
    formdata.append("handover_by", handoverBy);
    formdata.append("vehicle", vehicle);
    axios("Outward/outward", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/outward",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }

  const handleClickCustomer = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", newCustomer.newCustomerName);
    formdata.append("email", newCustomer.newCustomerEmail);
    formdata.append("mobile", newCustomer.newCustomerMobile);
    axios("Customers/customers", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          setCustomerId(response.data.customer_id)
          closeModal();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }

  const getReturnTypes = () => {
    axios("Xtras/return_type", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setReturnTypes(response.data.return_types);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const getUsers = () => {
    axios("Xtras/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setUsers(response.data.users);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const getInwardData = () => {

    setLoading(true);
    axios("Outward/inward_details?inward_no=" + inwardNo, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOutward(response.data.inward);
          setOutwardItems(response.data.inward_items);
          setLoading(false);
        } else {
          //setLoading(false);
          alert.show(response.data.error);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        // history.push({
        //   pathname: "/leads",
        //   state: { data: true },
        // });
      });
  }


  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getReturnTypes();
    getDepartments();
    getUsers();
  }, []);

  const customStyles = {
    content: {
      background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      width: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="row">
            <div class="col-md-4 form-group">
              <label for="invoiceNumber">Inward Number</label>
              <input type="text" id="inwardNo" name="inwardNo" class="form-control" onBlur={(e) => getInwardData(e)} onChange={(e) => setInwardNo(e.target.value)} value={inwardNo} />
            </div>


            <div class="col-md-4 form-group">
              <label for="department">Department</label>
              <SelectSearch options={departments} disabled value={outward.department_id} id="department" name="department" search placeholder="Choose your department" />
            </div>
            <div class="col-md-4 form-group">
              <label for="timeIn">Date</label>
              <input type="text" readOnly name="timeIn" class="form-control" value={outward.created_at} />
            </div>
            <div class="col-md-4 form-group">
              <label for="handover_by">Handed Over By</label>
              <SelectSearch options={users} id="handover_by" name="handover_by" onChange={(e) =>
                setHandoverBy(e)
              } value={handoverBy} search placeholder="Choose your User" />
            </div>
            <div class="col-md-4 form-group">
              <label for="handover_to">Handed Over To</label>
              <input type="text" name="handover_to" onChange={(e) => setHandoverTo(e.target.value)} class="form-control" value={handoverTo} />
            </div>
            <div class="col-md-4 form-group">
              <label for="handover_to">Vehicle/Shipment No</label>
              <input type="text" name="handover_to" class="form-control" onChange={(e) => setVehicle(e.target.value)} value={vehicle} />
            </div>
          </div>

          {outwardItems?.map((element, index) => (
            <div class="row main-box">
              <div class="col-md-6">
                <div class="row ">
                  <div class="col-md-12 form-group">
                    <label for="productId">Select Product</label>
                    <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="productQty">Quantity</label>
                    <input type="number" id="productQty" readOnly name="productQty" class="form-control" value={element.qty} />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="sku">SKU</label>
                    <input type="text" id="sku" readOnly name="sku" class="form-control" value={element.sku} />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                {element?.item_products?.map((itemElement, itemIndex) => (
                  <>
                    <div class="row itembox-p">
                      <div className="col-md-12">
                        <div class="col-md-12 form-group">
                          <label for="serial_number">Serial Number</label>
                          <input type="text" readOnly id="serial_number" name="serial_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serial_number} />
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="lpo_number">LPO Number</label>
                          <input type="text" readOnly id="lpo_number" name="lpo_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.lpo_number} />
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="internal_code">Internal Code</label>
                          <input type="text" readOnly id="internal_code" name="internal_code" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.internal_code} />
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="comment">Remarks</label>
                          <textarea id="comment" name="comment" class="form-control" rows="2" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.comment} >{itemElement.comment}</textarea>
                        </div>
                      </div>
                      <p class="">
                        <i className="fa fa-trash" onClick={() => removeFormItemFields(index, itemIndex)}></i>
                      </p>
                    </div>
                    <hr></hr>

                  </>
                ))}
              </div>
              <p class="deletebtn">
                <i className="fa fa-trash" onClick={() => removeFormFields(index)}></i>
              </p>
            </div>

          ))}
          <div class="  text-center"><hr></hr>
            <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div>

  )
}

export default AddOutward