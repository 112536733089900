import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import EditDepartment from "./EditDepartment";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import "./Catalog.css";
import IsAllowed from "../../Utils/IsAllowed";
function Departments() {
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [departmentID, setDepartmentID] = useState('');
    const [expandable, setExpandable] = useState('none');
    const alert = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const openModal = (id) => {
        setIsOpen(true);
        setDepartmentID(id);
    };

    function closeModal() {
        setIsOpen(false);
    }

    const [name, setName] = useState('');
    const [cc_email, setCc_email] = useState('');
    const [logoURL, setLogoURL] = useState('');
    const [buttonVisibility, setButtonVisibility] = useState('');

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    useEffect(() => {
        getDepartments();
    }, []);

    const openAddForm = () => {
        setExpandable('');
        setButtonVisibility('none');
    }

    const getDepartments = () => {
        axios("Xtras/departments", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartments(response.data.departments);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("logo", logoURL);
        formdata.append("cc_email", cc_email);
        axios("Xtras/departments", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    getDepartments();
                    alert.success(response.data.success);
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <EditDepartment setIsOpen={setIsOpen} getDepartments={getDepartments} department_id={departmentID}></EditDepartment>
            </Modal>
            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">Departments</h1>

                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div className="row add-department" style={{ display: expandable }}>
                            <div className="col-md-3 pl-0">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control form-control-lg"
                                ></input>
                            </div>
                            <div className="col-md-5">
                                <div className="department-logo">

                                    <input
                                        type="text"
                                        placeholder="Logo URL"
                                        value={logoURL}
                                        onChange={(e) => setLogoURL(e.target.value)}
                                        className="form-control form-control-lg"
                                    ></input>

                                    {logoURL &&
                                        (<img src={logoURL} ></img>)}

                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    placeholder="CC email coma separated"
                                    onChange={(e) => setCc_email(e.target.value)}
                                    value={cc_email}
                                    className="form-control form-control-lg"
                                ></input>
                            </div>
                            <div className="col-md-1">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                        <div className="col-md-12 text-right">
                            {IsAllowed(["20102"]) && (
                                <button
                                    className="btn btn-primary"
                                    style={{ display: buttonVisibility }}
                                    onClick={() =>
                                        openAddForm()
                                    }
                                >
                                    ADD DEPARTMENT
                                </button>
                            )}
                        </div>

                        <div class="container-fluid">
                            <div style={{ marginTop: 50 }}>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Logo</th>
                                                    <th>CC Email</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {departments.length > 0 ? departments.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.name}</td>
                                                            <td>{value.logo}</td>
                                                            <td>{value.cc_email}</td>
                                                            <td>{value.created_at}</td>
                                                            <td className="space-evenly">
                                                                {IsAllowed(["20103"]) && (
                                                                    <button className="btn btn-sm btn-primary" title="Edit" onClick={() => openModal(value.value)}><i className="fa fa-pen"></i></button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={6} className="text-center"> No Departments</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Departments;
