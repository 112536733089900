import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import AllJobCards from "./JobCardTabs/AllJobCards";
import OpenJobCards from "./JobCardTabs/OpenJobCards";
import ClosedJobCards from "./JobCardTabs/ClosedJobCards";
import ProcessingJobCards from "./JobCardTabs/ProcessingJobCards";

import IsAllowed from "../../Utils/IsAllowed";


import './JobCard.css';
function JobCard(props) {
    const [allcount, setAllCount] = useState(0);
    const [opencount, setOpenCount] = useState(0);
    const [closedcount, setClosedCount] = useState(0);
    const [processedcount, setProcessedCount] = useState(0);
    const [tabIndex, setTabIndex] = useState(props && props.location && props.location.tab ? props.location.tab : 0);
    return (
        <div>
            <div className="col-md-12 text-right">
                {IsAllowed(["70102"]) && (
                    <Link className="btn btn-primary " to="/add-job-card">
                        ADD JOB
                    </Link>
                )}
            </div>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>

                    <Tab>Open <span class="badge badge-danger badge-counter">{opencount}</span></Tab>
                    <Tab>Processed <span class="badge badge-warning badge-counter">{processedcount}</span></Tab>
                    <Tab>Closed <span class="badge badge-success badge-counter">{closedcount}</span></Tab>
                    <Tab>All <span class="badge badge-primary badge-counter">{allcount}</span></Tab>


                </TabList>



                <TabPanel>
                    <OpenJobCards setOpenCount={setOpenCount}></OpenJobCards>
                </TabPanel>
                <TabPanel>
                    <ProcessingJobCards setProcessedCount={setProcessedCount}></ProcessingJobCards>
                </TabPanel>
                <TabPanel>
                    <ClosedJobCards setClosedCount={setClosedCount}></ClosedJobCards>
                </TabPanel>
                <TabPanel>
                    <AllJobCards setAllCount={setAllCount}></AllJobCards>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default JobCard;