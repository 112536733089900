import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Vendors.css"


function EditVendor(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [vendorId, setVendorId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    const [vendor, setVendor] = useState({});

    let handleChange = (field, value) => {
        setVendor(prev => ({ ...prev, [field]: value }))
    }

    const getVendorDetails = () => {
        setLoading(true);
        axios("Vendors/vendor_detail?vendor_id=" + vendorId, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setVendor(response.data.vendor);
                    setLoading(false);
                } else {
                    //setLoading(false);
                    alert.show(response.data.error);
                    // history.push({
                    //   pathname: "/jobs",
                    //   state: { data: true },
                    // });
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
                // history.push({
                //   pathname: "/leads",
                //   state: { data: true },
                // });
            });
    };

    const handleClickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("vendor", JSON.stringify(vendor));
        axios("Vendors/update_vendor", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    history.push({
                        pathname: "/vendors",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }
    useEffect(() => {
        getVendorDetails();
    }, []);
    return (
        <div class="container">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >

                <form method="POST">

                    <div class="row bg-com-p">
                        <h3 className="text-center">Company Particulars</h3>

                        <div class="col-md-4 form-group">
                            <label for="company_name">Company name</label>
                            <input type="text" id="company_name" value={vendor.company_name} onChange={(e) => handleChange('company_name', e.target.value)} name="company_name" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="telephone">Telephone</label>
                            <input type="text" id="telephone" value={vendor.telephone} onChange={(e) => handleChange('telephone', e.target.value)} name="telephone" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="email">Email</label>
                            <input type="text" id="email" value={vendor.email} onChange={(e) => handleChange('email', e.target.value)} name="email" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="website">Website</label>
                            <input type="text" id="website" value={vendor.website} onChange={(e) => handleChange('website', e.target.value)} name="website" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="trade_license">Trade License No</label>
                            <input type="text" id="trade_license" value={vendor.trade_license} onChange={(e) => handleChange('trade_license', e.target.value)} name="trade_license" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="incorporation_date">Incorporation Date</label>
                            <input type="text" id="incorporation_date" value={vendor.incorporation_date} onChange={(e) => handleChange('incorporation_date', e.target.value)} name="incorporation_date" class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="address">Address</label>
                            <input type="text" id="address" value={vendor.address} onChange={(e) => handleChange('address', e.target.value)} name="address" class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="item_type">Product/Service Type</label>
                            <input type="text" id="item_type" value={vendor.item_type} onChange={(e) => handleChange('item_type', e.target.value)} name="item_type" class="form-control" />
                        </div>
                        <div class="col-md-12 form-group">
                            <label for="product_name" className="required">Company Type</label>
                            <div class="d-flex">
                                <div class="form-check d-flex pl-0">
                                    <label class="form-check-label" for="radio1">  Partnership</label>
                                    <input type="radio" name="company_type" id="radio1" value="Partnership" onChange={(e) => handleChange('company_type', 'partnership')} checked={vendor.company_type === 'partnership'} />
                                </div>
                                <div class="form-check d-flex">
                                    <label class="form-check-label" for="radio2"> LLC</label>
                                    <input type="radio" name="company_type" id="radio2" value="llc" onChange={(e) => handleChange('company_type', 'llc')} checked={vendor.company_type === 'llc'} />
                                </div>
                                <div class="form-check d-flex">
                                    <label class="form-check-label" for="radio3"> Sole Proprietorship</label>
                                    <input type="radio" name="company_type" id="radio3" value="sole proprietorship" onChange={(e) => handleChange('company_type', 'sole proprietorship')} checked={vendor.company_type === 'sole proprietorship'} />
                                </div>
                                <div class="form-check d-flex">
                                    <label class="form-check-label" for="radio4"> Other</label>
                                    <input type="radio" name="company_type" id="radio4" value="Other" onChange={(e) => handleChange('company_type', 'Other')} checked={vendor.company_type === 'Other'} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row bg-com-p">
                        <h3 className="text-center">Point of Contact Details</h3>


                        <div class="col-md-6 form-group">
                            <label for="poc_name">Name</label>
                            <input type="text" id="poc_name" name="poc_name" value={vendor.poc_name} onChange={(e) => handleChange('poc_name', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_designation">Designation</label>
                            <input type="text" id="poc_designation" name="poc_designation" value={vendor.poc_designation} onChange={(e) => handleChange('poc_designation', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_contact">Contact</label>
                            <input type="text" id="poc_contact" name="poc_contact" value={vendor.poc_contact} onChange={(e) => handleChange('poc_contact', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_email">Email</label>
                            <input type="text" id="poc_email" name="poc_email" value={vendor.poc_email} onChange={(e) => handleChange('poc_email', e.target.value)} class="form-control" />
                        </div>
                    </div>

                    <div class="row bg-com-p">
                        <h3 className="text-center">Point of Contact for Accounts</h3>
                        <div class="col-md-6 form-group">
                            <label for="poc_a_name">Name</label>
                            <input type="text" id="poc_a_name" name="poc_a_name" value={vendor.poc_a_name} onChange={(e) => handleChange('poc_a_name', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_a_designation">Designation</label>
                            <input type="text" id="poc_a_designation" name="poc_a_designation" value={vendor.poc_a_designation} onChange={(e) => handleChange('poc_a_designation', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_a_contact">Contact</label>
                            <input type="text" id="poc_a_contact" name="poc_a_contact" value={vendor.poc_a_contact} onChange={(e) => handleChange('poc_a_contact', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_a_email">Email</label>
                            <input type="text" id="poc_a_email" name="poc_a_email" value={vendor.poc_a_email} onChange={(e) => handleChange('poc_a_email', e.target.value)} class="form-control" />
                        </div>
                    </div>

                    <div class="row bg-com-p">
                        <h3 className="text-center">Point of Contact for RMA</h3>
                        <div class="col-md-6 form-group">
                            <label for="poc_r_name">Name</label>
                            <input type="text" id="poc_r_name" name="poc_r_name" value={vendor.poc_r_name} onChange={(e) => handleChange('poc_r_name', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_r_designation">Designation</label>
                            <input type="text" id="poc_r_designation" name="poc_r_designation" value={vendor.poc_r_designation} onChange={(e) => handleChange('poc_r_designation', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_r_contact">Contact</label>
                            <input type="text" id="poc_r_contact" name="poc_r_contact" value={vendor.poc_r_contact} onChange={(e) => handleChange('poc_r_contact', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="poc_r_email">Email</label>
                            <input type="text" id="poc_r_email" name="poc_r_email" value={vendor.poc_r_email} onChange={(e) => handleChange('poc_r_email', e.target.value)} class="form-control" />
                        </div>
                    </div>

                    <div class="row bg-com-p">
                        <h3 className="text-center">Transaction Details</h3>
                        <div class="col-md-6 form-group">
                            <label for="bank_name">Bank Name</label>
                            <input type="text" id="bank_name" name="bank_name" value={vendor.bank_name} onChange={(e) => handleChange('bank_name', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="tax_no">Tax No</label>
                            <input type="text" id="tax_no" name="tax_no" value={vendor.tax_no} onChange={(e) => handleChange('tax_no', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="account_title">Account Title</label>
                            <input type="text" id="account_title" name="account_title" value={vendor.account_title} onChange={(e) => handleChange('account_title', e.target.value)} class="form-control" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="reqaccount_numberuire_date">Account Number</label>
                            <input type="text" id="account_number" name="account_number" value={vendor.account_number} onChange={(e) => handleChange('account_number', e.target.value)} class="form-control" />
                        </div>
                    </div>
                    <div class="  text-center"><hr></hr>
                        <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
                    </div>
                </form>
            </LoadingOverlay>





        </div>
    )
}

export default EditVendor