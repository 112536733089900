import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import IsAllowed from "../../Utils/IsAllowed";
import "./Inward.css"

function EditInward(props) {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [returnTypes, setReturnTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productSku: "", brandName: "", categoryName: "", categoryId: "", brandId: "", returnType: 0, products: [], items: [{ serialNumber: "", lpoNumber: "", remarks: "" }] }])
  const [inwardId, setInwardId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [inward, setInward] = useState({});
  const [inwardItems, setInwardItems] = useState([]);


  // let handleChange = (i, e) => {
  //   let newFormValues = [...formValues];
  //   if (e.target.name == 'productQty') {
  //     let itemArray = [];
  //     for (let i = 1; i <= e.target.value; i++) {
  //       itemArray.push({ serialNumber: "", issue: "" });
  //     }
  //     newFormValues[i].items = itemArray;
  //   }
  //   newFormValues[i][e.target.name] = e.target.value;
  //   setFormValues(newFormValues);
  //   console.log(formValues);
  // }


  // const handleChangeItemStatus = (i, j, field, e) => {
  //   let newItemValues = [...Items];
  //   if (field == 'item_status') {
  //     newItemValues[i].item_products[j][field] = e;
  //   } else {
  //     newItemValues[i].item_products[j][field] = e.target.value;
  //   }
  //   setJobItems(newItemValues);
  // }
  let handleChangeItems = (i, j, e) => {
    let newItemValues = [...inwardItems];
    newItemValues[i].item_products[j][e.target.name] = e.target.value;
    setInwardItems(newItemValues);
  }

  const handleChangeReturn = (i, e) => {
    let newFormValues = [...inwardItems];
    newFormValues[i]['return_type'] = e;
    setInwardItems(newFormValues);
    console.log(formValues);
  }

  const handleChangeInward = (field, e) => {
    setInward({ ...inward, [field]: e })
  }

  const getInwardDetails = () => {
    setLoading(true);
    axios("Inward/inward_detail?inward_id=" + inwardId, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setInward(response.data.inward);
          setInwardItems(response.data.inward_items);
          setLoading(false);
        } else {
          //setLoading(false);
          alert.show(response.data.error);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        // history.push({
        //   pathname: "/leads",
        //   state: { data: true },
        // });
      });
  };

  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(inward.stored_at);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(inwardItems));
    formdata.append("inward_id", inwardId);
    formdata.append("received_by", inward.received_by);
    formdata.append("department_id", inward.department_id);
    formdata.append("delivered_by", inward.delivered_by);
    formdata.append("stored_at", inward.stored_at);
    axios("Inward/update_inward", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }
  const getReturnTypes = () => {
    axios("Inward/return_type", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setReturnTypes(response.data.return_types);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };
  const getUsers = () => {
    axios("Xtras/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setUsers(response.data.users);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  useEffect(() => {
    getUsers();
    getReturnTypes();
    getInwardDetails();
    getDepartments();
  }, []);


  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <div className="row">
          <div class="col-md-12">
            <form method="POST">
              <div class="row">
                <div class="col-md-2 form-group">
                  <label for="No">Inward Number</label>
                  <input type="text" name="No" value={inward.inward_no} class="form-control" />
                </div>
                <div class="col-md-2 form-group">
                  <label for="deliveredBy">Supplied By</label>
                  <input type="text" name="deliveredBy" value={inward.delivered_by} onChange={(e) => handleChangeInward('delivered_by', e.target.value)} class="form-control" />
                </div>
                <div class="col-md-3 form-group">
                  <label for="customerName">Received By</label>
                  <SelectSearch options={users} id="received_by" name="received_by" onChange={(e)=>handleChangeInward('received_by', e)} value={inward.received_by} search placeholder="Choose your User" />
                </div>
                <div class="col-md-3 form-group">
                  <label for="department">Department</label>
                  <SelectSearch disabled options={departments} value={inward.department_id} id="department" name="department" search placeholder="Choose your department" />
                </div>
                <div class="col-md-2 form-group">
                  <label for="storedAt">Stored At</label>
                  <input type="text" name="storedAt" value={inward.stored_at} onChange={(e) => handleChangeInward('stored_at', e.target.value)} class="form-control" />
                </div>
              </div>

              {inwardItems?.map((element, index) => (
                <div class="row main-box">
                  <div class="col-md-6">
                    <div class="row ">
                      <div class="col-md-12 form-group">
                        <label for="productId">Select Product</label>
                        <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="productQty">Quantity</label>
                        <input type="number" id="productQty" readOnly name="productQty" class="form-control" value={element.qty} />
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="returnType">Type</label>
                        <SelectSearch options={returnTypes} onChange={e => handleChangeReturn(index, e)} value={element.return_type} id="returnType" name="returnType" search placeholder="Choose your Return Type" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    {element?.item_products?.map((itemElement, itemIndex) => (
                      <div class="row itembox-p">
                        <div className="col-md-12">
                          <div class="col-md-12 form-group">
                            <label for="serial_number">Serial Number</label>
                            <input type="text" id="serial_number" name="serial_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serial_number} />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="remarks">remarks</label>
                            <textarea id="remarks" name="remarks" class="form-control" rows="2" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.remarks} >{itemElement.remarks}</textarea>
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="lpo_number">LPO Number</label>
                            <input type="text" id="lpo_number" name="lpo_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.lpo_number} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {IsAllowed(["50104"]) && (
              <div class="  text-center mb-3">
                <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Update</button>
              </div>
              )}
              <hr></hr>
            </form>
          </div>
        </div>


      </LoadingOverlay>
    </div>

  )
}

export default EditInward