import React, { useEffect, useState, useRef } from "react";

import { Link, useHistory } from "react-router-dom";
import "./Templates.css";
import $ from "jquery";
import { Avatar } from "@mui/material";
const toggle = () => {
  $("body").toggleClass("sidebar-toggled");
  $(".sidebar").toggleClass("toggled");
  if ($(".sidebar").hasClass("toggled")) {
    $(".sidebar .collapse").collapse("hide");
  }
};

function Header({ rand }) {
  // const history = useHistory();
  // const [user, setUser] = useState({});
  // const [notification, setNotification] = useState([]);
  // const [keyword, setKeyword] = useState();
  // const [isOpen2, setIsOpen2] = useState(false);
  // const [itemComments, setItemComments] = useState({});
  // const [comment, setComment] = useState("");
  // const [itemId, setItemId] = useState(0);
  // const messagesEndRef = useRef(null);

  // useEffect(() => {
  //   getNotificationData();
  // }, [rand]);
  // useEffect(() => {
  //   getUserData();
  // }, []);

  // const getItemMessages = (order_item_id) => {
  //   setIsOpen2(true);
  //   setItemId(order_item_id);

  //   axios(`Orders/get_order_item_comments?order_item_id=${order_item_id}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.error === false) {
  //         setItemComments(res.data.data);
  //         scrollToBottom();
  //       } else {
  //         setItemComments(res.data.data);
  //         scrollToBottom();
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const addOrderComment = (orderItemId, comment) => {
  //   let formdata = new FormData();
  //   formdata.append("order_item_id", orderItemId);
  //   formdata.append("comment", comment);
  //   axios(`Orders/add_order_item_comment`, {
  //     method: "POST",
  //     data: formdata,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.error === false) {
  //         getItemMessages(orderItemId);
  //         setComment("");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const getUserData = () => {
  //   axios(`Users/get_profile`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.error === true) {
  //       } else {
  //         if (!res.data.status) {
  //         } else {
  //           setUser(res.data.data);
  //         }
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const changeStoreLocation = (value) => {
  //   axios(`Users/change_store_location?location_id=${value}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {})
  //     .catch((err) => console.log(err));
  // };

  // const getNotificationData = () => {
  //   axios(`Users/get_notification`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.error === true) {
  //         setNotification([]);
  //       } else {
  //         setNotification(res.data.data);
  //         const audio = new Audio(
  //           "https://www.shopkees.com/img/audio/notify.mp3"
  //         );
  //         audio.play();
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const globelSearch = (e) => {
  //   if (e === "Enter") {
  //     window.location.replace(`/SearchOrders?keyword=${keyword}`);
  //   }
  // };

  const customStyles2 = {
    content: {
      //background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "20%",
      left: "25%",
      width: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  const logOut = () => {
    localStorage.removeItem("data");
  };
  return (
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top  toggled ">
      <button
        onClick={toggle}
        id="sidebarToggleTop"
        class="btn btn-link   rounded-circle mr-3"
      >
        <i class="fa fa-bars"></i>
      </button>

      <div class="main-header-left  p-relative col-md-6 col-8"> </div>

      <div class="col-md-2 col-2"> </div>

      <div className="nav-item dropdown no-arrow mx-1">
        <Link
          class="nav-link dropdown-toggle"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small"></span>
        </Link>
      </div>

      <ul class="navbar-nav w-100 justify-content-end ">
        <li class="nav-item dropdown no-arrow mx-1">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="messagesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-bell fa-fw"></i>

            <span class="badge badge-danger badge-counter">2</span>
          </a>

          <div
            class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="messagesDropdown"
          >
            <h6 class="dropdown-header">Message Center</h6>
          </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <li class="nav-item dropdown no-arrow">
          <div
            class="nav-link dropdown-toggle"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="mr-2 d-none d-lg-inline text-gray-600 small"></span>
            <Avatar>A</Avatar>
          </div>

          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <Link class="dropdown-item" to="/profile">
              <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </Link>
            <div class="dropdown-divider"></div>
            <Link class="dropdown-item" onClick={logOut} to="/login">
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
