import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Purchase.css"

function AddPurchase() {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [requireDate, setRequireDate] = useState('');
  const [department, setDepartment] = useState(1);
  const [purpose, setPurpose] = useState('');
  const [purposeList, setPurposeList] = useState([{ value: 'For Sales', name: 'For Sales' }, { value: 'for Stock', name: 'for Stock' }, { value: 'For Gift', name: 'For Gift' }]);
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productName: "", productSku: "", brandName: "", categoryName: "", categoryId: "", brandId: "", source_of_supply: "", remarks: "", products: [] }])



  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log(formValues);
  }

  let handleChangeProducts = (i, datas) => {
    let newFormValues = [...formValues];
    newFormValues[i].products = datas;
    setFormValues(newFormValues);
  }


  let handleChangeSelect = (i, e) => {
    axios("Xtras/product_detail?product_id=" + e, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let newItemValues = [...formValues];
          newItemValues[i]['productId'] = e;
          newItemValues[i]['brandName'] = response.data.product.brandName;
          newItemValues[i]['categoryName'] = response.data.product.categoryName;
          newItemValues[i]['brandId'] = response.data.product.brandId;
          newItemValues[i]['categoryId'] = response.data.product.categoryId;
          newItemValues[i]['productSku'] = response.data.product.productSku;
          newItemValues[i]['productName'] = response.data.product.name;
          setFormValues(newItemValues);
        } else {
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        alert.show(err.response.data.error);
      });

  }
  let addFormFields = () => {
    setFormValues([...formValues, { productId: "", productQty: "1", productName: "", productSku: "", brandName: "", categoryId: "", brandId: "", categoryName: "", source_of_supply: "", remarks: "", products: [] }
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }



  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(requireDate);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(formValues));
    formdata.append("department", department);
    formdata.append("required_date", requireDate);
    formdata.append("purpose", purpose);
    axios("Purchase/purchase", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/purchase",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }




  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };




  useEffect(() => {
    getDepartments();
  }, []);



  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="row">

            <div class="col-md-4 form-group">
              <label for="department " className="required">Department</label>
              <SelectSearch options={departments} onChange={setDepartment} value={department} id="department" name="department" search placeholder="Choose your department" />
            </div>
            <div class="col-md-4 form-group">
              <label for="purpose">Purpose of Purchase</label>
              <SelectSearch options={purposeList} onChange={(e) => setPurpose(e)} value={purpose} id="purpose" name="purpose" search placeholder="Select your Purpose" />
            </div>
            <div class="col-md-4 form-group">
              <label for="require_date">Required Date</label>
              <input type="date" id="require_date" name="require_date" class="form-control" onChange={(e) => setRequireDate(e.target.value)} value={requireDate} />
            </div>

          </div>

          {formValues.map((element, index) => (
            <div class="row main-box">
              <div class="col-md-8">
                <div class="row ">
                  <div class="col-md-10 form-group">
                    <label for="productId" className="required">Select Product</label>
                    <SelectSearch options={element.products} id="productId" name="productId" onChange={e => handleChangeSelect(index, e)} value={element.productId} search closeOnSelect placeholder="Search your product or sku" getOptions={(query) => {
                      return new Promise((resolve, reject) => {
                        axios(`Xtras/product?search=${query}&product_id=${element.productId}`, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: JSON.parse(localStorage.getItem("data")),
                          },
                        })
                          .then(({ data }) => {
                            handleChangeProducts(index, data.products)
                            //setProducts(data.products);
                          })
                          .catch(reject);
                      });

                    }} />
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="productQty" className="required">Quantity</label>
                    <input type="number" id="productQty" name="productQty" class="form-control" onChange={e => handleChange(index, e)} value={element.productQty} />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="productSku">SKU</label>
                    <input type="text" id="productSku" readOnly name="productSku" class="form-control" onChange={e => handleChange(index, e)} value={element.productSku} />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="brandName">Brand</label>
                    <input type="text" id="brandName" readOnly name="brandName" class="form-control" onChange={e => handleChange(index, e)} value={element.brandName} />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="categoryName">Category</label>
                    <input type="text" id="categoryName" readOnly name="categoryName" class="form-control" onChange={e => handleChange(index, e)} value={element.categoryName} />
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="source_of_supply">Source Of Supply</label>
                    <textarea id="source_of_supply" name="source_of_supply" class="form-control" rows="2" onChange={e => handleChange(index, e)} value={element.source_of_supply} >{element.source_of_supply}</textarea>
                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="row itembox-p">
                  <div class="col-md-12 form-group">
                    <label for="remarks">Remarks</label>
                    <textarea id="remarks" name="remarks" class="form-control" rows="8" onChange={e => handleChange(index, e)} value={element.remarks} >{element.remarks}</textarea>
                  </div>
                </div>
              </div>
              {
                index ?
                  <p class="deletebtn">
                    <i className="fa fa-trash" onClick={() => removeFormFields(index)}></i>
                  </p>
                  : null
              }

            </div>
          ))}
          <div class="button-section text-right">
            <button type="button" class="btn btn-info btn-sm  " onClick={() => addFormFields()}>Add</button>
          </div>
          <div class="  text-center"><hr></hr>
            <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div>

  )
}

export default AddPurchase