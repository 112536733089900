import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import IsAllowed from "./Utils/IsAllowed";
import axios from "./Utils/Axios";
import { AuthContext } from "./Context/AuthContext";
import Dashboard from "./Components/Dashboard/Dashboard";
import Header from "./Components/Templates/Header";
import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Login from "./Components/Login/Login";
import Profile from "./Components/Login/Profile";
import Departments from "./Components/Catalog/Departments";
import Users from "./Components/UserManagement/Users";
import Roles from "./Components/UserManagement/Roles";
import AddRole from "./Components/UserManagement/AddRole";
import AddUser from "./Components/UserManagement/AddUser";
import EditUser from "./Components/UserManagement/EditUser";
import Jobs from "./Components/Jobs/Jobs";
import AddJob from "./Components/Jobs/AddJob";
import EditJob from "./Components/Jobs/EditJob";
import Purchase from "./Components/Purchase/Purchase";
import AddPurchase from "./Components/Purchase/AddPurchase";
import EditPurchase from "./Components/Purchase/EditPurchase";
import Inward from "./Components/Inward/Inward";
import AddInward from "./Components/Inward/AddInward";
import EditInward from "./Components/Inward/EditInward";
import Outward from "./Components/Outward/Outward";
import AddOutward from "./Components/Outward/AddOutward";
import EditOutward from "./Components/Outward/EditOutward";
import EditRole from "./Components/UserManagement/EditRole";
import AddVendor from "./Components/Vendor/AddVendor";
import Vendors from "./Components/Vendor/Vendors";
import EditVendor from "./Components/Vendor/EditVendor";
import AddJobCard from "./Components/JobCard/AddJobCard";
import EditJobCard from "./Components/JobCard/EditJobCard";
import JobCard from "./Components/JobCard/JobCard";
import Feedback from "./Components/Feedback/Feedback";

function App() {
  const { role, setRole, setPermissions } = useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`Login/get_user_role_permission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setRole(res.data.users.user_role_id);
            setPermissions(res.data.permissions);
          } else {
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log(err);
          }
        });
    }
  });
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>
      <Switch>
        <LoginHeader />
      </Switch>

      <div id="content-wrapper">
        <div id="content">
          <Switch>
            <Route path="/login" exact component={Login} />
            <ProtectedRoute path="/" exact component={Dashboard} />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/jobs" exact component={Jobs} />
            <ProtectedRoute path="/add-jobs" exact component={AddJob} />
            <ProtectedRoute path="/purchase" exact component={Purchase} />
            <ProtectedRoute
              path="/add-purchase"
              exact
              component={AddPurchase}
            />
            <ProtectedRoute path="/inward" exact component={Inward} />
            <ProtectedRoute path="/add-inward" exact component={AddInward} />
            <ProtectedRoute path="/outward" exact component={Outward} />
            <ProtectedRoute path="/add-outward" exact component={AddOutward} />
            <ProtectedRoute path="/departments" exact component={Departments} />
            <ProtectedRoute path="/users" exact component={Users} />
            <ProtectedRoute path="/add-user" exact component={AddUser} />
            <ProtectedRoute path="/roles" exact component={Roles} />
            <ProtectedRoute path="/add-role" exact component={AddRole} />
            <ProtectedRoute path="/vendors" exact component={Vendors} />
            <ProtectedRoute path="/add-vendor" exact component={AddVendor} />
            <ProtectedRoute path="/job-cards" exact component={JobCard} />
            <ProtectedRoute path="/add-job-card" exact component={AddJobCard} />

            <ProtectedRoute path="/edit-job/:id" exact component={EditJob} />
            <ProtectedRoute path="/feedbacks" exact component={Feedback} />

            <ProtectedRoute
              path="/edit-purchase/:id"
              exact
              component={EditPurchase}
            />
            <ProtectedRoute
              path="/edit-inward/:id"
              exact
              component={EditInward}
            />
            <ProtectedRoute
              path="/edit-outward/:id"
              exact
              component={EditOutward}
            />
            <ProtectedRoute
              path="/edit-vendor/:id"
              exact
              component={EditVendor}
            />

            <ProtectedRoute
              path="/edit-job-card/:id"
              exact
              component={EditJobCard}
            />
            <ProtectedRoute path="/edit-user/:id" exact component={EditUser} />
            <ProtectedRoute path="/edit-role/:id" exact component={EditRole} />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header rand={Math.random()} /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
