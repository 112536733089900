import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import Switch from "react-switch";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import IsAllowed from "../../Utils/IsAllowed";
import ItemStatusHistory from "./ItemStatusHistory";
import "./Jobs.css"

function EditJob(props) {
  const alert = useAlert();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [returnType, setReturnType] = useState(1);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [comment, setComment] = useState('');
  const [returnTypes, setReturnTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [itemStatuses, setItemStatuses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const [products, setProducts] = useState([]);
  const [pos, setPos] = useState('');
  const [itemId, setItemId] = useState(null);




  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productSku: "", brandName: "", categoryName: "", categoryId: "", brandId: "", isWarranty: 0, itemReceived: 1, products: [], items: [{ serialNumber: "", issue: "", accessories: "", preliminary_inspection: "" }] }])
  const [jobId, setJobId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [job, setJob] = useState({});
  const [jobItems, setJobItems] = useState([]);
  const [file, setFile] = useState([]);
  const [images, setImages] = useState([]);

  const handleFileChange = async (event, id) => {
    const files = event.target.files;
    await setFile(files);
    await console.log(file);
    await handleFormSubmit(event, id)

  };


  const deleteImage = (id) => {
    setLoading(true);
    axios("Jobs/delete_image?image_id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          getJobDetails();
        } else {
          setLoading(false);
          alert.show(response.data.error);

        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });

  }

  const handleFormSubmit = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const files = e.target.files;
    let formdata = new FormData();
    formdata.append("product_item_id", id);
    for (let i = 0; i < files.length; i++) {
      formdata.append(`image_${i}`, files[i]);
    }



    axios(`Jobs/image_upload`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getJobDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response);
      });
  };

  let handleChange = (i, e) => {
    let newFormValues = [...jobItems];
    if (e.target.name == 'qty') {
      let itemArray = [];
      for (let i = 1; i <= e.target.value; i++) {
        itemArray.push({ serialNumber: "", issue: "", accessories: "", preliminary_inspection: "" });
      }
      newFormValues[i].item_products = itemArray;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setJobItems(newFormValues);
    //console.log(formValues);
  }
  const handleProductChange = (i) => {
    setPos(i);
    setIsOpen(true);
  }


  const handleChangeItemStatus = (i, j, field, e) => {
    let newItemValues = [...jobItems];
    if (field == 'item_status') {
      newItemValues[i].item_products[j][field] = e;
    } else {
      newItemValues[i].item_products[j][field] = e.target.value;
    }
    setJobItems(newItemValues);
  }

  const switchStatus = (i, e) => {
    let status;
    e ? (status = 1) : (status = 0);
    let newFormValues = [...jobItems];
    newFormValues[i]['item_received'] = status;
    setJobItems(newFormValues);
  }
  let handleChangeItems = (i, j, e) => {
    let newItemValues = [...jobItems];
    newItemValues[i].item_products[j][e.target.name] = e.target.value;
    setJobItems(newItemValues);
  }


  const updateStatus = () => {

    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", jobId);
    formdata.append("comment", comment);
    formdata.append("status", job.status);

    axios(`Jobs/update_status`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getJobDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  let handleChangeProducts = (i, datas) => {
    setProducts(datas);
  }
  const updateAssignee = () => {

    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", jobId);
    formdata.append("assigned_to", job.assigned_to);

    axios(`Jobs/update_assignee`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getJobDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const updateItemStatus = (itemId, status, comment) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", itemId);
    formdata.append("comment", comment);
    formdata.append("status", status);

    axios(`Jobs/update_item_status`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getJobDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  }



  const getJobDetails = () => {
    setLoading(true);
    axios("Jobs/job_detail?job_id=" + jobId, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setJob(response.data.job);
          setJobItems(response.data.job_items);
          setStatusHistory(response.data.status_history);
          setItemImages(response.data.item_images);
          setDepartmentUsers(response.data.department_users);
          setLoading(false);
        } else {
          //setLoading(false);
          alert.show(response.data.error);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        // history.push({
        //   pathname: "/leads",
        //   state: { data: true },
        // });
      });
  };


  let handleChangeSelect = (i, e) => {
    axios("Xtras/product_detail?product_id=" + e, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log("pos", pos);
        if (response.status === 200) {
          let newItemValues = [...jobItems];
          newItemValues[i]['product_id'] = e;
          newItemValues[i]['product_name'] = response.data.product.name;;
          newItemValues[i]['brandName'] = response.data.product.brandName;
          newItemValues[i]['categoryName'] = response.data.product.categoryName;
          newItemValues[i]['brand_id'] = response.data.product.brandId;
          newItemValues[i]['category_id'] = response.data.product.categoryId;
          newItemValues[i]['product_sku'] = response.data.product.productSku;
          setJobItems(newItemValues);
          setIsOpen(false);
          setPos('');
        } else {
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        //alert.show(err.response.data.error);
      });

  }







  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(jobItems));
    formdata.append("job_id", jobId);
    formdata.append("customer_id", job.customer_id);
    formdata.append("department_id", job.department_id);
    formdata.append("invoice_number", job.invoice_no);
    formdata.append("return_type", job.return_type);
    axios("Jobs/update_job", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }
  const getReturnTypes = () => {
    axios("Xtras/return_type", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setReturnTypes(response.data.return_types);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getStatuses = () => {
    axios("Xtras/statuses", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setStatuses(response.data.statuses);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getItemStatuses = () => {
    axios("Xtras/item_statuses", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setItemStatuses(response.data.item_statuses);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeItemModal() {
    setIsItemOpen(false);
  }

  const openModal = (id) => {
    setItemId(id);
    setIsItemOpen(true);
  };

  useEffect(() => {
    getReturnTypes();
    getJobDetails();
    getStatuses();
    getItemStatuses();
    getDepartments();
  }, []);

  const customStyles = {
    content: {
      background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      width: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };



  return (
    <div class="container">
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="newCustomerName">Product</label>
              <SelectSearch options={products} id="productId" name="productId" onChange={e => handleChangeSelect(pos, e)} value={1} search closeOnSelect placeholder="Choose your product" getOptions={(query) => {
                return new Promise((resolve, reject) => {
                  if (query.length > 3) {
                    axios("Xtras/product?search=" + query, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: JSON.parse(localStorage.getItem("data")),
                      },
                    })
                      .then(({ data }) => {
                        handleChangeProducts(1, data.products)
                        //setProducts(data.products);
                      })
                      .catch(reject);
                  }
                });

              }} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isItemOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeItemModal}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <ItemStatusHistory itemId={itemId}></ItemStatusHistory>

        <input
          type="button"
          className="btn btn-primary"
          onClick={closeItemModal}
          value="Close"
        />
      </Modal>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <div className="row">
          <div class="col-md-12">
            <form method="POST">
              <div class="row">
                <div class="col-md-2 form-group">
                  <label for="jobNo">Job Number</label>
                  <input type="text" readOnly name="jobNo" value={job.job_no} class="form-control" />
                </div>
                <div class="col-md-2 form-group">
                  <label for="invoiceNumber">Invoice Number</label>
                  <input type="text" readOnly name="invoiceNumber" value={job.invoice_no} class="form-control" />
                </div>
                <div class="col-md-2 form-group">
                  <label for="customerName">Customer Name</label>
                  <input type="text" readOnly name="customerName" value={job.name} class="form-control" />
                </div>
                <div class="col-md-3 form-group">
                  <label for="purchase_date">Purchase Date</label>
                  <input type="date" id="purchase_date" name="purchase_date" class="form-control" readOnly value={job.purchase_date} />
                </div>

                <div class="col-md-3 form-group">
                  <label for="department">Department</label>
                  <SelectSearch disabled options={departments} value={job.department_id} id="department" name="department" search placeholder="Choose your department" />
                </div>

              </div>

              {jobItems?.map((element, index) => (
                <div class="row main-box">
                  <div class="col-md-12">
                    <div class="row ">
                      <div class="col-md-8 form-group">
                        <label for="productId">Select Product</label>
                        <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                      </div>
                      <div class="col-md-4 form-group">
                        <label for="productQty">Quantity</label>
                        <input type="number" readOnly id="productQty" onChange={e => handleChange(index, e)} name="qty" class="form-control" value={element.qty} />
                      </div>
                      <div class="col-md-4 form-group">
                        <label for="product_name">Warranty</label>
                        <div class="d-flex">
                          <div class="form-check d-flex">
                            <label class="form-check-label" for="radio1">  Yes</label>
                            <input type="radio" readOnly name={"isWarranty" + index} value="1" checked={element.is_warranty === '1'} />
                          </div>
                          <div class="form-check d-flex">
                            <label class="form-check-label" for="radio2"> No</label>
                            <input type="radio" readOnly name={"isWarranty" + index} value="0" checked={element.is_warranty === '0'} />
                          </div>
                          <div class="form-check d-flex">
                            <label class="form-check-label" for="radio2"> Partial</label>
                            <input type="radio" readOnly name={"isWarranty" + index} value="2" checked={element.is_warranty === '2'} />
                          </div>
                        </div>
                      </div>
                      {element.is_warranty === '2' &&
                        <div class="col-md-4 form-group">
                          <label for="warranty_comment"> Explain </label>
                          <textarea id="warranty_comment" readOnly name="warrantyComment" class="form-control" rows="1" value={element.warranty_comment} >{element.warranty_comment}</textarea>
                        </div>
                      }
                      <div class="col-md-2 form-group">
                        <label for="itemReceived" >Item Received</label>
                        <Switch
                          name="itemReceived"
                          onChange={e => switchStatus(index, e)}
                          checked={element.item_received === 0 ? 0 : 1}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    {element?.item_products?.map((itemElement, itemIndex) => (
                      <>
                        <div class="row itembox-p">
                          <div className="col-md-8">
                            <div class="row">
                              <div class="col-md-4 form-group">
                                <label for="serial_number">Serial Number</label>
                                <input type="text" id="serial_number" name="serial_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serial_number} />
                              </div>
                              <div class="col-md-4 form-group">
                                <label for="lpo_number">LPO Number</label>
                                <input type="text" id="lpo_number" name="lpo_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.lpo_number} />
                              </div>
                              <div class="col-md-4 form-group">
                                <label for="issue">Defect</label>
                                <textarea id="issue" name="issue" class="form-control" rows="1" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.issue} >{itemElement.issue}</textarea>
                              </div>

                              <div class="col-md-6 form-group">
                                <label for="accessories">Accessories Given </label>
                                <textarea id="accessories" name="accessories" class="form-control" rows="1" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.accessories} >{itemElement.accessories}</textarea>
                              </div>
                              <div class="col-md-6 form-group">
                                <label for="preliminary_inspection">Preliminary Inspection </label>
                                <textarea id="preliminary_inspection" name="preliminary_inspection" class="form-control" rows="1" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.preliminary_inspection} >{itemElement.preliminary_inspection}</textarea>
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              <div className="form-group">
                                {IsAllowed(["10109"]) && (

                                  <label for="inputTag">
                                    Upload Image <br />
                                    <i class="fa fa-2x fa-camera"></i>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      disabled={file.length === 5}
                                      className="form-control"
                                      id="inputTag"
                                      onChange={(e) => handleFileChange(e, itemElement.id)}
                                      multiple
                                    />
                                  </label>

                                )}
                              </div>
                              <div className="row border pt-3 pb-3">
                                {itemElement.item_images.map((image, index) => (
                                  <>
                                    <div className="col-md-2" >
                                      <a href={image.image} target="__blank"><img width="100" height="70" src={image.image} alt={`image_${index}`} /></a>
                                    </div>
                                    {IsAllowed(["10110"]) && (
                                      <div className="col-md-1" >
                                        <span className="btn btn-sm btn-danger" onClick={() => deleteImage(image.id)}>
                                          <i className="fa fa-times" ></i>
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="col-md-12 status-box">
                              <form method="POST">
                                <div class="form-group">
                                  <label for="statuses">Status</label>
                                  <SelectSearch options={itemStatuses} id="statuses" name="status" onChange={e => handleChangeItemStatus(index, itemIndex, 'item_status', e)} value={itemElement.item_status} search placeholder="Choose your Status" />
                                </div>
                                <div class="form-group">
                                  <label for="comment">Comment:</label>
                                  <textarea id="comment" name="comment" class="form-control" rows="2" onChange={e => handleChangeItemStatus(index, itemIndex, 'comment', e)}  >{itemElement.comment}</textarea>
                                </div>
                                <div class="form-group text-center mb-0">
                                  {IsAllowed(["10107"]) && (
                                    <button type="button" class="btn btn-info btn-xs " onClick={() => updateItemStatus(itemElement.id, itemElement.item_status, itemElement.comment)}  >Change</button>
                                  )}
                                  <button type="button" class="btn  btn-xs  btn-dark ml-2 " onClick={() => openModal(itemElement.id)} ><i className="fa fa-list"></i></button>
                                </div>
                              </form>
                            </div>
                          </div>

                        </div>
                        <br></br>
                      </>
                    ))}
                  </div>

                </div>
              ))}
              {IsAllowed(["10104"]) && (
                <div class="  text-center mb-3">
                  <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Update</button>
                </div>
              )}

              <hr></hr>
            </form>
          </div>

          <div class="col-md-6">
            <div class="col-md-12 status-box">
              <form method="POST">
                <div class="form-group">
                  <label for="statuses">Status</label>
                  <SelectSearch options={statuses} id="statuses" name="statuses" onChange={(e) =>
                    setJob({
                      ...job,
                      status: e,
                    })
                  } value={job.status} search placeholder="Choose your Status" />
                </div>
                <div class="form-group">
                  <label for="comment">Comment:</label>
                  <textarea id="comment" name="comment" class="form-control" onChange={(e) => setComment(e.target.value)}  >{comment}</textarea>
                </div>
                <div class="form-group text-center mb-0">
                  {IsAllowed(["10105"]) && (
                    <button type="button" class="btn btn-info btn-sm " onClick={updateStatus}  >Change</button>
                  )}
                </div>
              </form>
            </div>
            <div class="col-md-12 status-box">
              <form method="POST">
                <div class="form-group">
                  <label for="statuses">Assigned To</label>
                  <SelectSearch options={departmentUsers} id="assigned_to" name="assigned_to" onChange={(e) =>
                    setJob({
                      ...job,
                      assigned_to: e,
                    })
                  } value={job.assigned_to} search placeholder="Choose your User" />
                </div>
                <div class="form-group text-center mb-0">
                  {IsAllowed(["10106"]) && (
                    <button type="button" class="btn btn-info btn-sm " onClick={updateAssignee}  >Change</button>
                  )}
                </div>
              </form>
            </div>
          </div>


          <div class="col-md-6 ">
            <h5 className="text-center">Status history</h5>
            <ul class="timeline">
              {statusHistory && statusHistory.map((value, index) => {
                return (
                  <>
                    <li className={"alert alert-" + value.style}>
                      <h5>{value.name}</h5>
                      <div class="mb-0 d-flex justify-content-between"><span><i className="fa fa-calendar"></i> {value.created_date}      </span><span> <i className="fa fa-clock"></i> {value.created_time}</span></div>
                      <p class="w-100 timeline-text">{value.comment}</p>
                      <p><i className="fa fa-user"></i> {value.updated_by}</p>
                    </li>
                  </>
                )
              })}

            </ul>
          </div>



        </div>


      </LoadingOverlay>
    </div>

  )
}

export default EditJob