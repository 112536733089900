import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import Switch from "react-switch";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import IsAllowed from "../../Utils/IsAllowed";
import "./JobCard.css"

function EditJobCard(props) {
    const alert = useAlert();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isItemOpen, setIsItemOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [pos, setPos] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [statusHistory, setStatusHistory] = useState([]);
    const [comment, setComment] = useState('');






    const [jobId, setJobId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    const [job, setJob] = useState({});
    const [jobItems, setJobItems] = useState([]);
    const [file, setFile] = useState([]);









    let handleChange = (i, e) => {
        let newFormValues = [...jobItems];
        if (e.target.name == 'qty') {
            let itemArray = [];
            for (let i = 1; i <= e.target.value; i++) {
                itemArray.push({ serialNumber: "", remarks: "", charge: "" });
            }
            newFormValues[i].item_products = itemArray;
        }
        newFormValues[i][e.target.name] = e.target.value;
        setJobItems(newFormValues);
        //console.log(formValues);
    }





    let handleChangeItems = (i, j, e) => {
        let newItemValues = [...jobItems];
        newItemValues[i].item_products[j][e.target.name] = e.target.value;
        setJobItems(newItemValues);
    }

    const updateStatus = () => {

        setLoading(true);
        let formdata = new FormData();
        formdata.append("id", jobId);
        formdata.append("comment", comment);
        formdata.append("status", job.status);

        axios(`Jobcard/update_status`, {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    getJobDetails();
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };





    let handleChangeProducts = (i, datas) => {
        setProducts(datas);
    }


    const getJobDetails = () => {
        setLoading(true);
        axios("Jobcard/job_card_detail?job_id=" + jobId, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setJob(response.data.job);
                    setJobItems(response.data.job_items);
                    setStatusHistory(response.data.status_history);
                    setLoading(false);
                } else {
                    //setLoading(false);
                    alert.show(response.data.error);
                    // history.push({
                    //   pathname: "/jobs",
                    //   state: { data: true },
                    // });
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
                // history.push({
                //   pathname: "/leads",
                //   state: { data: true },
                // });
            });
    };


    let handleChangeSelect = (i, e) => {
        axios("Xtras/product_detail?product_id=" + e, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log("pos", pos);
                if (response.status === 200) {
                    let newItemValues = [...jobItems];
                    newItemValues[i]['product_id'] = e;
                    newItemValues[i]['product_name'] = response.data.product.name;;
                    newItemValues[i]['brandName'] = response.data.product.brandName;
                    newItemValues[i]['categoryName'] = response.data.product.categoryName;
                    newItemValues[i]['brand_id'] = response.data.product.brandId;
                    newItemValues[i]['category_id'] = response.data.product.categoryId;
                    newItemValues[i]['product_sku'] = response.data.product.productSku;
                    setJobItems(newItemValues);
                    setIsOpen(false);
                    setPos('');
                } else {
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                console.log(err);
                //alert.show(err.response.data.error);
            });

    }







    const handleClickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("products", JSON.stringify(jobItems));
        formdata.append("job_id", jobId);
        formdata.append("customer_id", job.customer_id);
        formdata.append("delivery_date", job.delivery_date);
        axios("Jobcard/update_job_card", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    // history.push({
                    //   pathname: "/jobs",
                    //   state: { data: true },
                    // });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }


    function closeModal() {
        setIsOpen(false);
    }

    function closeItemModal() {
        setIsItemOpen(false);
    }

    const openModal = (id) => {
        setItemId(id);
        setIsItemOpen(true);
    };

    const getStatuses = () => {
        axios("Xtras/statuses", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setStatuses(response.data.statuses);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    useEffect(() => {
        getJobDetails();
        getStatuses();

    }, []);

    const customStyles = {
        content: {
            background: "linear-gradient(180deg,#00475d,#00a6d9)",
            top: "30%",
            left: "40%",
            width: "25%",
            right: "auto",
            bottom: "auto",
            marginRight: "0%",
            transform: "fade in",
        },
    };

    const customStyles2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };



    return (
        <div class="container">
            <Modal
                isOpen={isOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="view">
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="newCustomerName">Product</label>
                            <SelectSearch options={products} id="productId" name="productId" onChange={e => handleChangeSelect(pos, e)} value={1} search closeOnSelect placeholder="Choose your product" getOptions={(query) => {
                                return new Promise((resolve, reject) => {
                                    if (query.length > 3) {
                                        axios("Xtras/product?search=" + query, {
                                            method: "GET",
                                            headers: {
                                                "Content-Type": "application/x-www-form-urlencoded",
                                                Authorization: JSON.parse(localStorage.getItem("data")),
                                            },
                                        })
                                            .then(({ data }) => {
                                                handleChangeProducts(1, data.products)
                                                //setProducts(data.products);
                                            })
                                            .catch(reject);
                                    }
                                });

                            }} />
                        </div>
                    </div>
                </div>
            </Modal>
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <div className="row">
                    <div class="col-md-12">
                        <form method="POST">
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <label for="jobNo">Job Card Number</label>
                                    <input type="text" readOnly name="jobNo" value={job.job_no} class="form-control" />
                                </div>

                                <div class="col-md-4 form-group">
                                    <label for="customerName">Customer Name</label>
                                    <input type="text" readOnly name="customerName" value={job.name} class="form-control" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="delivery_date">Delivery date </label>
                                    <input type="text" readOnly name="delivery_date" value={job.delivery_date} class="form-control" />
                                </div>

                            </div>

                            {jobItems?.map((element, index) => (
                                <div class="row main-box">
                                    <div class="col-md-12">
                                        <div class="row ">
                                            <div class="col-md-8 form-group">
                                                <label for="productId">Select Product</label>
                                                <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                                            </div>
                                            <div class="col-md-4 form-group">
                                                <label for="productQty">Quantity</label>
                                                <input type="number" readOnly id="productQty" onChange={e => handleChange(index, e)} name="qty" class="form-control" value={element.qty} />
                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        {element?.item_products?.map((itemElement, itemIndex) => (
                                            <>
                                                <div class="row itembox-p">
                                                    <div className="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-4 form-group">
                                                                <label for="serial_number">Serial Number</label>
                                                                <input type="text" id="serial_number" name="serial_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serial_number} />
                                                            </div>

                                                            <div class="col-md-4 form-group">
                                                                <label for="remarks">Remarks</label>
                                                                <textarea id="remarks" name="remarks" class="form-control" rows="1" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.remarks} >{itemElement.remarks}</textarea>
                                                            </div>
                                                            <div class="col-md-4 form-group">
                                                                <label for="charge">Charge</label>
                                                                <input type="text" id="charge" name="charge" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.charge} />
                                                            </div>


                                                        </div>
                                                        {/* <div className="col-md-12 text-center">
                              <div className="form-group">
                                {IsAllowed(["10109"]) && (

                                  <label for="inputTag">
                                    Upload Image <br />
                                    <i class="fa fa-2x fa-camera"></i>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      disabled={file.length === 5}
                                      className="form-control"
                                      id="inputTag"
                                      onChange={(e) => handleFileChange(e, itemElement.id)}
                                      multiple
                                    />
                                  </label>

                                )}
                              </div>
                              <div className="row border pt-3 pb-3">
                                {itemElement.item_images.map((image, index) => (
                                  <>
                                    <div className="col-md-2" >
                                      <a href={image.image} target="__blank"><img width="100" height="70" src={image.image} alt={`image_${index}`} /></a>
                                    </div>
                                    {IsAllowed(["10110"]) && (
                                      <div className="col-md-1" >
                                        <span className="btn btn-sm btn-danger" onClick={() => deleteImage(image.id)}>
                                          <i className="fa fa-times" ></i>
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div> */}
                                                    </div>
                                                </div>
                                                <br></br>
                                            </>
                                        ))}
                                    </div>

                                </div>
                            ))}
                            {IsAllowed(["70104"]) && (
                                <div class="  text-center mb-3">
                                    <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Update</button>
                                </div>
                            )}

                            <hr></hr>
                        </form>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-12 status-box">
                            <form method="POST">
                                <div class="form-group">
                                    <label for="statuses">Status</label>
                                    <SelectSearch options={statuses} id="statuses" name="statuses" onChange={(e) =>
                                        setJob({
                                            ...job,
                                            status: e,
                                        })
                                    } value={job.status} search placeholder="Choose your Status" />
                                </div>
                                <div class="form-group">
                                    <label for="comment">Comment:</label>
                                    <textarea id="comment" name="comment" class="form-control" onChange={(e) => setComment(e.target.value)}  >{comment}</textarea>
                                </div>
                                <div class="form-group text-center mb-0">
                                    {IsAllowed(["10105"]) && (
                                        <button type="button" class="btn btn-info btn-sm " onClick={updateStatus}  >Change</button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>


                    <div class="col-md-6 ">
                        <h5 className="text-center">Status history</h5>
                        <ul class="timeline">
                            {statusHistory && statusHistory.map((value, index) => {
                                return (
                                    <>
                                        <li className={"alert alert-" + value.style}>
                                            <h5>{value.name}</h5>
                                            <div class="mb-0 d-flex justify-content-between"><span><i className="fa fa-calendar"></i> {value.created_date}      </span><span> <i className="fa fa-clock"></i> {value.created_time}</span></div>
                                            <p class="w-100 timeline-text">{value.comment}</p>
                                            <p><i className="fa fa-user"></i> {value.updated_by}</p>
                                        </li>
                                    </>
                                )
                            })}

                        </ul>
                    </div>
                </div>





            </LoadingOverlay>
        </div>

    )
}

export default EditJobCard