import axios from "../../Utils/Axios";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import "./Jobs.css"
function ItemStatusHistory(props) {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    useEffect(() => {
        getItemStatusHistory();
    }, [props.itemId]);

    const getItemStatusHistory = () => {
        setLoading(true);
        axios("Jobs/job_item_status_history?item_id=" + props.itemId, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setStatuses(response.data.item_status_history);
                    setLoading(false);
                } else {
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
                // history.push({
                //   pathname: "/leads",
                //   state: { data: true },
                // });
            });
    };

    return (
        <LoadingOverlay
            active={loading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.9)",
                }),
                spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                    },
                }),
            }}
            spinner
            text="Loading ..."
        >
            <div>
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div style={{ marginTop: 50 }}>
                            <div className="table-responsive"></div>
                            <table className="table table-bordered  ">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Status</th>
                                        <th>Comment</th>
                                        <th>Updated By</th>
                                        <th>Updated Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {statuses.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{value.name}</td>
                                                <td>{value.comment}</td>
                                                <td>{value.updated_by}</td>
                                                <td>{value.created_date}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}

export default ItemStatusHistory;