import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import Switch from "react-switch";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./JobCard.css"
import ProductPopup from "../Common/ProductPopup";
import CustomerPopup from "../Common/CustomerPopup";

function AddJobCard() {
    const alert = useAlert();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenProduct, setIsOpenProduct] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [productIndex, setProductIndex] = useState('');
    const [customerId, setCustomerId] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [newCustomer, setNewCustomer] = useState({ newCustomerName: "", newCustomerEmail: "", newCustomerMobile: "", newCustomerAddress: "" })
    const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productSku: "", productName: "", brandName: "", categoryName: "", categoryId: "", brandId: "", products: [], items: [{ serialNumber: "", remarks: "", charge: "0" }] }])
    const [newProduct, setNewProduct] = useState({ new_product_name: "", new_model: "", new_category: "", new_brand: "" })


    let handleChange = async (i, e) => {
        let newFormValues = [...formValues];
        if (e.target.name == 'productQty') {
            let itemArray = [];
            for (let i = 1; i <= e.target.value; i++) {
                itemArray.push({ serialNumber: "", remarks: "", charge: "0", });
            }
            newFormValues[i].items = itemArray;
            newFormValues[i][e.target.name] = e.target.value;
            setFormValues(newFormValues);
        } else {
            newFormValues[i][e.target.name] = e.target.value;
            setFormValues(newFormValues);
        }
    }



    let handleChangeProducts = (i, datas) => {
        let newFormValues = [...formValues];
        newFormValues[i].products = datas;
        setFormValues(newFormValues);
    }

    let handleChangeCustomer = (e) => {
        let newItemValues = { ...newCustomer }
        newItemValues[e.target.name] = e.target.value;
        setNewCustomer(newItemValues);
    }

    let handleChangeProduct = (e) => {
        let newItemValues = { ...newProduct }
        newItemValues[e.target.name] = e.target.value;
        setNewProduct(newItemValues);
    }



    let handleChangeItems = (i, j, e) => {
        let newItemValues = [...formValues];
        newItemValues[i].items[j][e.target.name] = e.target.value;
        setFormValues(newItemValues);
    }

    let handleChangeSelect = (i, e) => {
        axios("Xtras/product_detail?product_id=" + e, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    let newItemValues = [...formValues];
                    newItemValues[i]['productId'] = e;
                    newItemValues[i]['brandName'] = response.data.product.brandName;
                    newItemValues[i]['categoryName'] = response.data.product.categoryName;
                    newItemValues[i]['brandId'] = response.data.product.brandId;
                    newItemValues[i]['categoryId'] = response.data.product.categoryId;
                    newItemValues[i]['productSku'] = response.data.product.productSku;
                    newItemValues[i]['productName'] = response.data.product.name;
                    setFormValues(newItemValues);
                    closeModalProduct()

                } else {
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                console.log(err);
                //alert.show(err.response.data.error);
            });

    }

    let addFormFields = () => {
        setFormValues([...formValues, { productId: "", productQty: "1", productSku: "", productName: "", brandName: "", categoryId: "", brandId: "", categoryName: "", products: [], items: [{ serialNumber: "", remarks: "", charge: "0" }] }
        ])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    // let handleSubmit = (event) => {
    //     event.preventDefault();
    //     alert(JSON.stringify(formValues));
    // }


    const handleClickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("products", JSON.stringify(formValues));
        formdata.append("customerId", customerId);
        formdata.append("delivery_date", deliveryDate);
        axios("Jobcard/jobcard", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    history.push({
                        pathname: "/job-cards",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }

    const handleClickProduct = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", newProduct.new_product_name);
        formdata.append("model_number", newProduct.new_model);
        formdata.append("brand_id", newProduct.new_brand);
        formdata.append("root_category", newProduct.new_category);
        axios("Xtras/product", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    closeModalProduct();
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }


    const handleClickCustomer = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", newCustomer.newCustomerName);
        formdata.append("email", newCustomer.newCustomerEmail);
        formdata.append("mobile", newCustomer.newCustomerMobile);
        formdata.append("address", newCustomer.newCustomerAddress);
        axios("Customers/customers", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    setCustomerId(response.data.customer_id)
                    closeModal();
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }


    const getBrands = () => {
        axios("Xtras/brands", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setBrands(response.data.brands);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getCategories = () => {
        axios("Xtras/categories", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCategories(response.data.categories);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    useEffect(() => {
        getCategories();
        getBrands();
    }, []);



    function closeModal() {
        setIsOpen(false);
    }

    function closeModalProduct() {
        setIsOpenProduct(false);
    }


    const customStyles = {
        content: {
            width: "95%",
        },
    };

    const customStylesProduct = {
        content: {
            top: "30%",
            left: "40%",
            width: "25%",
            right: "auto",
            bottom: "auto",
            marginRight: "0%",
        },
    };

    return (
        <div class="container">
            <Modal
                isOpen={isOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <CustomerPopup setCustomerId={setCustomerId} setCustomerName={setCustomerName} closeModal={closeModal}></CustomerPopup>
            </Modal>
            <Modal
                isOpen={isOpenProduct}
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModalProduct}
                style={customStylesProduct}
                contentLabel="Product Modal"
            >
                <div className="view">
                    <div class="modal-header">
                        <button type="button" class="close" onClick={closeModalProduct}>&times;</button>
                    </div>
                    <div class="row">
                        <div class=" col-md-12 form-group">
                            <label for="new_product_name">Name</label>
                            <input type="text" id="new_product_name" name="new_product_name" value={newCustomer.new_product_name} onChange={e => handleChangeProduct(e)} class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="new_model">Model</label>
                            <input type="text" id="new_model" name="new_model" value={newCustomer.new_model} onChange={e => handleChangeProduct(e)} class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="newCustomerEmail">Categories</label>
                            <select
                                className="form-control form-control-lg"
                                id="new_category"
                                name="new_category"
                                onChange={e => handleChangeProduct(e)}
                            >
                                <option value="0">Select Categories </option>
                                {categories &&
                                    categories.map((val, index) => {
                                        return (
                                            <option key={index} value={val.id}>
                                                {val.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-12 form-group">
                            <label for="newCustomerMobile">Brands</label>
                            <select
                                className="form-control form-control-lg"
                                id="new_brand"
                                name="new_brand"
                                onChange={e => handleChangeProduct(e)}
                            >
                                <option value="0">Select Brand </option>
                                {brands &&
                                    brands.map((val, index) => {
                                        return (
                                            <option key={index} value={val.id}>
                                                {val.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-12  text-center">
                            <button className="btn btn-sm btn-success" onClick={(e) => handleClickProduct(e)}  >Submit</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <form method="POST">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="customer" className="required">Choose Customer</label>
                            <input type="text" id="customerName" readOnly name="customerName" class="form-control" value={customerName} />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="delivery_date">&nbsp;</label>
                            <button type="button" class="btn  btn-info ml-2" title="add customer" onClick={() => setIsOpen(true)}>Select Customer</button>
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="delivery_date">Delivery Date</label>
                            <input type="date" id="delivery_date" name="delivery_date" class="form-control form-control-lg " onChange={(e) => setDeliveryDate(e.target.value)} value={deliveryDate} />
                        </div>
                    </div>



                    {formValues.map((element, index) => (
                        <div class="row main-box">
                            <div class="col-md-8">
                                <div class="row ">
                                    <div class="col-md-10 form-group">
                                        <label for="productId" className="required">Select Product</label>
                                        <SelectSearch options={element.products} id="productId" name="productId" onChange={e => handleChangeSelect(index, e)} value={element.productId} search closeOnSelect placeholder="Search your product or sku" getOptions={(query) => {
                                            return new Promise((resolve, reject) => {

                                                axios(`Xtras/product?search=${query}&product_id=${element.productId}`, {
                                                    method: "GET",
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                        Authorization: JSON.parse(localStorage.getItem("data")),
                                                    },
                                                })
                                                    .then(({ data }) => {
                                                        handleChangeProducts(index, data.products)
                                                        //setProducts(data.products);
                                                    })
                                                    .catch(reject);

                                            });

                                        }} />
                                    </div>
                                    <div class="col-md-1 form-group">
                                        <label for="" >&nbsp;</label>
                                        <button type="button" class="btn  btn-info " title="add Product" onClick={() => setIsOpenProduct(true)}>+</button>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label for="productSku" className="required">SKU</label>
                                        <input type="text" id="productSku" readOnly name="productSku" class="form-control" onChange={e => handleChange(index, e)} value={element.productSku} />
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label for="brandName" className="required">Brand</label>
                                        <input type="text" id="brandName" readOnly name="brandName" class="form-control" onChange={e => handleChange(index, e)} value={element.brandName} />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label for="categoryName" className="required">Category</label>
                                        <input type="text" id="categoryName" readOnly name="categoryName" class="form-control" onChange={e => handleChange(index, e)} value={element.categoryName} />
                                    </div>
                                    <div class="col-md-2 form-group">
                                        <label for="productQty" className="required">Quantity</label>
                                        <input type="number" id="productQty" name="productQty" class="form-control" onChange={e => handleChange(index, e)} value={element.productQty} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                {element.items.map((itemElement, itemIndex) => (
                                    <div class="row itembox-p">
                                        <div class="col-md-12 form-group">
                                            <label for="serialNumber" >Serial Number - {itemIndex + 1}</label>
                                            <input type="text" id="serialNumber" name="serialNumber" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serialNumber} />
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label for="remarks">Remarks - {itemIndex + 1}</label>
                                            <textarea id="remarks" name="remarks" class="form-control" rows="1" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.remarks} >{itemElement.remarks}</textarea>
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label for="charge" >Charge - {itemIndex + 1}</label>
                                            <input type="text" id="charge" name="charge" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.charge} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {
                                index ?
                                    <p class="deletebtn">
                                        <i className="fa fa-trash" onClick={() => removeFormFields(index)}></i>
                                    </p>
                                    : null
                            }

                        </div>
                    ))}
                    <div class="button-section text-right">
                        <button type="button" class="btn btn-info btn-sm  " onClick={() => addFormFields()}>Add</button>
                    </div>
                    <div class="  text-center"><hr></hr>
                        <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
                    </div>
                </form>
            </LoadingOverlay>
        </div>

    )
}

export default AddJobCard