
import React, { useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import IsAllowed from "../../Utils/IsAllowed";


function CustomerPopup(props) {
    const history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [total, setTotal] = useState();
    const [per_page, setPer_page] = useState(10);
    const [loading, setLoading] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [page, setPage] = useState(1);
    const [newCustomer, setNewCustomer] = useState({ newCustomerName: "", newCustomerEmail: "", newCustomerMobile: "", newCustomerAddress: "" })


    const [search, setSearch] = useState("");

    const alert = useAlert();










    useEffect(() => {
        customer_list();
    }, [page, per_page, search]);



    const customer_list = () => {
        setLoading(true);
        axios(
            `Customers/customers_list?page=${page}&per_page=${per_page}&search=${search}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setLoading(false);
                    setCustomers(res.data.data);
                    setTotal(res.data.total);
                } else {
                    setLoading(false);
                    alert.show(res.error);
                }
            })
            .catch((err) => console.log(err));
    };

    let handleChangeCustomer = (e) => {
        let newItemValues = { ...newCustomer }
        newItemValues[e.target.name] = e.target.value;
        setNewCustomer(newItemValues);
    }


    const handleClickCustomer = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", newCustomer.newCustomerName);
        formdata.append("email", newCustomer.newCustomerEmail);
        formdata.append("mobile", newCustomer.newCustomerMobile);
        formdata.append("address", newCustomer.newCustomerAddress);
        axios("Customers/customers", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    props.setCustomerId(response.data.customer_id);
                    props.setCustomerName(newCustomer.newCustomerName);
                    props.closeModal();
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    }

    return (
        <div>
            <div class="modal-header">
                <button type="button" class="close" onClick={props.closeModal}>&times;</button>
            </div>
            <br></br>
            {formShow == true ? (
                <div className="container">
                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label for="newCustomerName">Name</label>
                            <input type="text" id="newCustomerName" name="newCustomerName" value={newCustomer.newCustomerName} onChange={e => handleChangeCustomer(e)} class="form-control" />
                        </div>
                        <div class="col-md-3 form-group">
                            <label for="newCustomerEmail">email</label>
                            <input type="text" id="newCustomerEmail" className="required" name="newCustomerEmail" value={newCustomer.newCustomerEmail} onChange={e => handleChangeCustomer(e)} class="form-control" />
                        </div>
                        <div class=" col-md-3 form-group">
                            <label for="newCustomerMobile">Mobile</label>
                            <input type="text" id="newCustomerMobile" name="newCustomerMobile" value={newCustomer.newCustomerMobile} onChange={e => handleChangeCustomer(e)} class="form-control" />
                        </div>
                        <div class=" col-md-3 form-group">
                            <label for="newCustomerAddress">Address</label>
                            <input type="text" id="newCustomerAddress" name="newCustomerAddress" value={newCustomer.newCustomerAddress} onChange={e => handleChangeCustomer(e)} class="form-control" />
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-md-12">
                            <button className="btn btn-sm btn-success" onClick={(e) => handleClickCustomer(e)}  >Submit</button>
                            <button className="btn btn-sm btn-danger ml-2" onClick={() => setFormShow(false)}  >Hide</button>
                        </div>
                    </div>
                </div>

            ) : ''
            }

            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">All Customers</h1>
                <div className="col-md-12 text-right">
                    {formShow == false &&
                        <button className="btn btn-primary btn-sm  " onClick={() => setFormShow(true)}>
                            ADD Customer
                        </button>
                    }


                </div>
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search...."
                                />
                            </div>

                        </div>
                        <div style={{ marginTop: 50 }}>
                            <div className="table-responsive">
                                <LoadingOverlay
                                    active={loading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "rgba(255, 255, 255, 0.9)",
                                        }),
                                        spinner: (base) => ({
                                            ...base,
                                            width: "75px",
                                            "& svg circle": {
                                                stroke: "rgba(0, 0, 0, 0.8)",
                                            },
                                        }),
                                    }}
                                    spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                    text="..."
                                >
                                    <table className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {customers.length > 0 ? customers.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{value.id}</td>
                                                        <td>{value.name}</td>
                                                        <td>{value.email}</td>
                                                        <td>{value.mobile}</td>
                                                        <td>
                                                            <a className="btn btn-info btn-sm " onClick={() => { props.setCustomerId(value.id); props.setCustomerName(value.name); props.closeModal() }}> Select </a>
                                                        </td>
                                                    </tr>
                                                );
                                            }) : (<td colSpan={8} className="text-center"> No Customers</td>)}
                                        </tbody>
                                    </table>
                                </LoadingOverlay>

                                <div>
                                    <Box p={2} display="flex" justifyContent="center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                color="secondary"
                                                count={total}
                                                page={page}
                                                onChange={(event, value) => {
                                                    setPage(value);
                                                }}
                                            />
                                        </Stack>
                                        <Stack spacing={2}>
                                            <Select
                                                value={per_page}
                                                onChange={(e) => setPer_page(e.target.value)}
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                            </Select>
                                        </Stack>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CustomerPopup;
