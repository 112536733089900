import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import AllJobs from './JobTabs/AllJobs';
import OpenJobs from './JobTabs/OpenJobs';
import ClosedJobs from './JobTabs/ClosedJobs';
import IsAllowed from "../../Utils/IsAllowed";


import './Jobs.css';
function Jobs(props) {
  const [allcount, setAllCount] = useState(0);
  const [opencount, setOpenCount] = useState(0);
  const [closedcount, setClosedCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(props && props.location && props.location.tab ? props.location.tab : 0);
  return (
    <div>
      <div className="col-md-12 text-right">
        {IsAllowed(["10102"]) && (
          <Link className="btn btn-primary " to="/add-jobs">
            ADD JOB
          </Link>
        )}
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          
          <Tab>Open <span class="badge badge-danger badge-counter">{opencount}</span></Tab>

          <Tab>Closed <span class="badge badge-success badge-counter">{closedcount}</span></Tab>
          <Tab>All <span class="badge badge-primary badge-counter">{allcount}</span></Tab>
         

        </TabList>


        
        <TabPanel>
          <OpenJobs setOpenCount={setOpenCount}></OpenJobs>
        </TabPanel>
        <TabPanel>
          <ClosedJobs setClosedCount={setClosedCount}></ClosedJobs>
        </TabPanel>
        <TabPanel>
          <AllJobs setAllCount={setAllCount}></AllJobs>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Jobs;
