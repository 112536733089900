import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Inward.css"

function AddInward() {
  const alert = useAlert();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storedAt, setStoreAt] = useState('');
  const [deliveredBy, setDeliveredBy] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(1);
  const [receivedBy, setReceivedBy] = useState(0);
  const [users, setUsers] = useState([]);
  const [returnTypes, setReturnTypes] = useState([]);
  const [newCustomer, setNewCustomer] = useState({ newCustomerName: "", newCustomerEmail: "", newCustomerMobile: "" })
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productName: "", productSku: "", brandName: "", categoryName: "", categoryId: "", brandId: "", returnType: 1, products: [], items: [{ serialNumber: "", lpoNumber: "", remarks: "" }] }])



  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name == 'productQty') {
      let itemArray = [];
      for (let i = 1; i <= e.target.value; i++) {
        itemArray.push({ serialNumber: "", lpoNumber: "", remarks: "" });
      }
      newFormValues[i].items = itemArray;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log(formValues);
  }

  const handleChangeReturn = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i]['returnType'] = e;
    setFormValues(newFormValues);
    console.log(formValues);
  }



  let handleChangeProducts = (i, datas) => {
    let newFormValues = [...formValues];
    newFormValues[i].products = datas;
    setFormValues(newFormValues);
  }

  let handleChangeItems = (i, j, e) => {
    let newItemValues = [...formValues];
    newItemValues[i].items[j][e.target.name] = e.target.value;
    setFormValues(newItemValues);
  }
  let handleChangeCustomer = (e) => {
    let newItemValues = { ...newCustomer }
    newItemValues[e.target.name] = e.target.value;
    setNewCustomer(newItemValues);
  }

  let handleChangeSelect = (i, e) => {
    axios("Xtras/product_detail?product_id=" + e, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let newItemValues = [...formValues];
          newItemValues[i]['productId'] = e;
          newItemValues[i]['brandName'] = response.data.product.brandName;
          newItemValues[i]['categoryName'] = response.data.product.categoryName;
          newItemValues[i]['brandId'] = response.data.product.brandId;
          newItemValues[i]['categoryId'] = response.data.product.categoryId;
          newItemValues[i]['productSku'] = response.data.product.productSku;
          newItemValues[i]['productName'] = response.data.product.name;
          setFormValues(newItemValues);
        } else {
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        alert.show(err.response.data.error);
      });

  }




  let addFormFields = () => {
    setFormValues([...formValues, { productId: "", productName: "", productQty: "1", productSku: "", brandName: "", categoryId: "", brandId: "", categoryName: "", returnType: 1, products: [], items: [{ serialNumber: "", lpoNumber: "", remarks: "" }] }
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  // let handleSubmit = (event) => {
  //     event.preventDefault();
  //     alert(JSON.stringify(formValues));
  // }


  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(formValues));
    formdata.append("department", department);
    formdata.append("stored_at", storedAt);
    formdata.append("customer_id", customerId);
    formdata.append("received_by", receivedBy);
    formdata.append("delivered_by", deliveredBy);
    axios("Inward/inward", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/inward",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }

  const handleClickCustomer = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", newCustomer.newCustomerName);
    formdata.append("email", newCustomer.newCustomerEmail);
    formdata.append("mobile", newCustomer.newCustomerMobile);
    axios("Customers/customers", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          setCustomerId(response.data.customer_id)
          closeModal();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }


  const getReturnTypes = () => {
    axios("Inward/return_type", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setReturnTypes(response.data.return_types);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getUsers = () => {
    axios("Xtras/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setUsers(response.data.users);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }




  useEffect(() => {
    getReturnTypes();
    getDepartments();
    getUsers();
  }, []);

  const customStyles = {
    content: {
      top: "30%",
      left: "40%",
      width: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
    },
  };


  return (
    <div class="container">
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div class="modal-header">
            <button type="button" class="close" onClick={closeModal}>&times;</button>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="newCustomerName">Name</label>
              <input type="text" id="newCustomerName" name="newCustomerName" value={newCustomer.newCustomerName} onChange={e => handleChangeCustomer(e)} class="form-control " />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="newCustomerEmail">email</label>
              <input type="text" id="newCustomerEmail" className="required" name="newCustomerEmail" value={newCustomer.newCustomerEmail} onChange={e => handleChangeCustomer(e)} class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class=" col-md-12 form-group">
              <label for="newCustomerMobile">Mobile</label>
              <input type="text" id="newCustomerMobile" name="newCustomerMobile" value={newCustomer.newCustomerMobile} onChange={e => handleChangeCustomer(e)} class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class=" col-md-6 ">
              <button className="btn btn-sm btn-danger" onClick={(e) => handleClickCustomer(e)}  >Submit</button>
            </div>
          </div>
        </div>
      </Modal>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="row">
            <div class="col-md-3 form-group">
              <label for="department" className="required">Department</label>
              <SelectSearch options={departments} onChange={setDepartment} value={department} id="department" name="department" search placeholder="Choose your department" />
            </div>
            <div class="col-md-3 form-group">
              <label for="deliveredBy">Supplied By</label>
              <input type="text" id="deliveredBy" name="deliveredBy" class="form-control form-control-lg" onChange={e => setDeliveredBy(e.target.value)} value={deliveredBy} placeholder="" />
            </div>
            <div class="col-md-3 form-group">
              <label for="storedAt">Stored At</label>
              <input type="text" id="storedAt" name="storedAt" class="form-control form-control-lg" onChange={e => setStoreAt(e.target.value)} value={storedAt} placeholder="" />
            </div>
            <div class="col-md-3 form-group">
              <label for="received_by"> Received By</label>
              <div className="d-flex">
                <SelectSearch options={users} id="received_by" name="received_by" onChange={(e) =>
                  setReceivedBy(e)
                } value={receivedBy} search placeholder="Choose your User" />

              </div>
            </div>


          </div>

          {formValues.map((element, index) => (
            <div class="row main-box">
              <div class="col-md-8">
                <div class="row ">
                  <div class="col-md-12 form-group">
                    <label for="productId" className="required">Select Product</label>
                    <SelectSearch options={element.products} id="productId" name="productId" onChange={e => handleChangeSelect(index, e)} value={element.productId} search closeOnSelect placeholder="Search your product or sku" getOptions={(query) => {
                      return new Promise((resolve, reject) => {
                        //if (query.length > 3) {
                        axios(`Xtras/product?search=${query}&product_id=${element.productId}`, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: JSON.parse(localStorage.getItem("data")),
                          },
                        })
                          .then(({ data }) => {
                            handleChangeProducts(index, data.products)
                            //setProducts(data.products);
                          })
                          .catch(reject);
                        // }
                      });

                    }} />
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="productSku">SKU</label>
                    <input type="text" id="productSku" readOnly name="productSku" class="form-control" onChange={e => handleChange(index, e)} value={element.productSku} />
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="brandName">Brand</label>
                    <input type="text" id="brandName" readOnly name="brandName" class="form-control" onChange={e => handleChange(index, e)} value={element.brandName} />
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="categoryName">Category</label>
                    <input type="text" id="categoryName" readOnly name="categoryName" class="form-control" onChange={e => handleChange(index, e)} value={element.categoryName} />
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="productQty" className="required">Quantity</label>
                    <input type="number" id="productQty" name="productQty" class="form-control" onChange={e => handleChange(index, e)} value={element.productQty} />
                  </div>
                  <div class="col-md-12 form-group">
                    <label for="returnType">Type</label>
                    <SelectSearch options={returnTypes} onChange={e => handleChangeReturn(index, e)} value={element.returnType} id="returnType" name="returnType" search placeholder="Choose your Return Type" />
                  </div>

                </div>
              </div>
              <div class="col-md-4">
                {element.items.map((itemElement, itemIndex) => (
                  <div class="row itembox-p">
                    <div class="col-md-12 form-group">
                      <label for="serialNumber">Serial Number - {itemIndex + 1}</label>
                      <input type="text" id="serialNumber" name="serialNumber" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serialNumber} />
                    </div>
                    <div class="col-md-12 form-group" hidden={element.returnType == 2 ? true : false}>
                      <label for="issue">LPO Number - {itemIndex + 1}</label>
                      <input type="text" readOnly={element.returnType == 2 ? true : false} id="lpoNumber" name="lpoNumber" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.lpoNumber} />
                    </div>

                    <div class="col-md-12 form-group">
                      <label for="remarks">Remark/Reason - {itemIndex + 1}</label>
                      <textarea id="remarks" name="remarks" class="form-control" rows="2" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.remarks} >{itemElement.remarks}</textarea>
                    </div>
                  </div>
                ))}
              </div>
              {
                index ?
                  <p class="deletebtn">
                    <i className="fa fa-trash" onClick={() => removeFormFields(index)}></i>
                  </p>
                  : null
              }

            </div>
          ))}
          <div class="button-section text-right">
            <button type="button" class="btn btn-info btn-sm  " onClick={() => addFormFields()}>Add</button>
          </div>
          <div class="  text-center"><hr></hr>
            <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div>

  )
}

export default AddInward