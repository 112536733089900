import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import IsAllowed from "../../Utils/IsAllowed";
import "./Outward.css"

function EditOutward(props) {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [returnType, setReturnType] = useState(1);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [comment, setComment] = useState('');
  const [returnTypes, setReturnTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [formValues, setFormValues] = useState([{ productId: "", productQty: "1", productSku: "", brandName: "", categoryName: "", categoryId: "", brandId: "", isWarranty: 0, products: [], items: [{ serialNumber: "", issue: "" }] }])
  const [outwardId, setOutwardId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [job, setJob] = useState({});
  const [jobItems, setJobItems] = useState([]);
  const [outward, setOutward] = useState({});
  const [outwardItems, setOutwardItems] = useState([]);
  const [inwardNo, setInwardNo] = useState('');
  const [users, setUsers] = useState([]);
  const [handoverBy, setHandoverBy] = useState('');
  const [handoverTo, setHandoverTo] = useState('');
  const [vehicle, setVehicle] = useState('');


  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name == 'productQty') {
      let itemArray = [];
      for (let i = 1; i <= e.target.value; i++) {
        itemArray.push({ serialNumber: "", issue: "" });
      }
      newFormValues[i].items = itemArray;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log(formValues);
  }



  let handleChangeItems = (i, j, e) => {
    let newItemValues = [...outwardItems];
    newItemValues[i].item_products[j][e.target.name] = e.target.value;
    setOutwardItems(newItemValues);
  }

  const handleChangeOutward = (field, e) => {
    setOutward({ ...outward, [field]: e })
  }










  const getOutwardDetails = () => {
    setLoading(true);
    axios("Outward/outward_detail?outward_id=" + outwardId, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOutward(response.data.job);
          setOutwardItems(response.data.outward_items);
          setLoading(false);
        } else {
          //setLoading(false);
          alert.show(response.data.error);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        // history.push({
        //   pathname: "/leads",
        //   state: { data: true },
        // });
      });
  };







  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(outwardItems));
    formdata.append("outward_id", outwardId);
    formdata.append("handover_to", outward.handover_to);
    formdata.append("department", outward.department_id);
    formdata.append("inward_id", outward.id);
    formdata.append("vehicle", outward.vehicle);
    formdata.append("handover_by", outward.handover_by);
    axios("Outward/update_outward", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/outward",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }
  const getReturnTypes = () => {
    axios("Xtras/return_type", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setReturnTypes(response.data.return_types);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getStatuses = () => {
    axios("Xtras/statuses", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setStatuses(response.data.statuses);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const getUsers = () => {
    axios("Xtras/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setUsers(response.data.users);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  useEffect(() => {
    getUsers();
    getOutwardDetails();
    getStatuses();
    getDepartments();
  }, []);


  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <div className="row">
          <div class="col-md-12">
            <form method="POST">
              <div class="row">
                <div class="col-md-3 form-group">
                  <label for="outwardNo">Inward Number</label>
                  <input type="text" readOnly id="outwardNo" name="outwardNo" class="form-control" value={outward.outward_no} />
                </div>
                <div class="col-md-3 form-group">
                  <label for="inwardNo">Inward Number</label>
                  <input type="text" readOnly id="inwardNo" name="inwardNo" class="form-control" value={outward.inward_no} />
                </div>


                <div class="col-md-3 form-group">
                  <label for="department">Department</label>
                  <SelectSearch options={departments} disabled value={outward.department_id} id="department" name="department" search placeholder="Search your department" />
                </div>
                <div class="col-md-3 form-group">
                  <label for="timeIn">Date</label>
                  <input type="text" readOnly name="timeIn" class="form-control" value={outward.created_at} />
                </div>
                <div class="col-md-4 form-group">
                  <label for="handover_by">Handed Over By</label>
                  <SelectSearch options={users} onChange={(e) => handleChangeOutward('handover_by', e)} id="handover_by" name="handover_by" value={outward.handover_by} search placeholder="Search your User" />
                </div>
                <div class="col-md-4 form-group">
                  <label for="handover_to">Handed Over To</label>
                  <input type="text" name="handover_to" onChange={(e) => handleChangeOutward('handover_to', e.target.value)} class="form-control" value={outward.handover_to} />
                </div>
                <div class="col-md-4 form-group">
                  <label for="vehicle">Vehicle/Shipment No</label>
                  <input type="text" name="vehicle" class="form-control" onChange={(e) => handleChangeOutward('vehicle', e.target.value)} value={outward.vehicle} />
                </div>
              </div>

              {outwardItems?.map((element, index) => (
                <div class="row main-box">
                  <div class="col-md-6">
                    <div class="row ">
                      <div class="col-md-12 form-group">
                        <label for="productId">Select Product</label>
                        <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="productQty">Quantity</label>
                        <input type="number" id="productQty" readOnly name="productQty" class="form-control" value={element.qty} />
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="sku">SKU</label>
                        <input type="text" id="sku" readOnly name="sku" class="form-control" value={element.sku} />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    {element?.item_products?.map((itemElement, itemIndex) => (
                      <>
                        <div class="row itembox-p">
                          <div className="col-md-12">
                            <div class="col-md-12 form-group">
                              <label for="serial_number">Serial Number</label>
                              <input type="text" readOnly id="serial_number" name="serial_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.serial_number} />
                            </div>
                            <div class="col-md-12 form-group">
                              <label for="lpo_number">LPO Number</label>
                              <input type="text" readOnly id="lpo_number" name="lpo_number" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.lpo_number} />
                            </div>
                            <div class="col-md-12 form-group">
                              <label for="internal_code">Internal code</label>
                              <input type="text" readOnly id="internal_code" name="internal_code" class="form-control" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.internal_code} />
                            </div>
                            <div class="col-md-12 form-group">
                              <label for="remarks">Remarks</label>
                              <textarea id="remarks" name="remarks" class="form-control" rows="2" onChange={e => handleChangeItems(index, itemIndex, e)} value={itemElement.remarks} >{itemElement.remarks}</textarea>
                            </div>
                          </div>
                        </div>
                        <hr></hr>

                      </>
                    ))}
                  </div>
                </div>

              ))}
              {IsAllowed(["60104"]) && (
                <div class="  text-center"><hr></hr>
                  <button type="button" class="btn btn-danger" onClick={(e) => handleClickSubmit(e)}>Submit</button>
                </div>
              )}
            </form>
          </div>

        </div>


      </LoadingOverlay>
    </div>

  )
}

export default EditOutward