import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Users.css"



function AddRole() {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [name, setName] = useState('');

  const [data, setData] = useState([{
    label: 'RMA',
    id: 'rma',
    value: '1',
    children: [
      {
        label: 'RMA List',
        id: 'rma_list',
        value: '101',
        children: [
          {
            label: 'RMA List View',
            value: '10101',
            checked: false,
          },
          {
            label: 'RMA Add',
            value: '10102',
            checked: false,
          },
          {
            label: 'RMA Detail View',
            value: '10103',
            checked: false,
          },
          {
            label: 'RMA Upadate',
            value: '10104',
            checked: false,
          },
          {
            label: 'RMA Status Change',
            value: '10105',
            checked: false,
          },
          {
            label: 'RMA Change Assignee',
            value: '10106',
            checked: false,
          },
          {
            label: 'RMA Item Status Change',
            value: '10107',
            checked: false,
          },
          {
            label: 'RMA Print',
            value: '10108',
            checked: false,
          },
          {
            label: 'RMA Product Add',
            value: '10109',
            checked: false,
          },
          {
            label: 'RMA Product Delete',
            value: '10110',
            checked: false,
          },
          {
            label: 'RMA Mail',
            value: '10111',
            checked: false,
          }
        ],
      },
    ],
  }, {
    label: 'Catalogs',
    id: 'catalogs',
    value: '2',
    children: [
      {
        label: 'Department List',
        id: 'department_list',
        value: '201',
        children: [
          {
            label: 'Department List View',
            value: '20101',
            checked: false,
          },
          {
            label: 'Department Add',
            value: '20102',
            checked: false,
          },
          {
            label: 'Department Edit',
            value: '20103',
            checked: false,
          },
        ],
      },
    ],
  },
  {
    label: 'User Management',
    id: 'user',
    value: '3',
    children: [
      {
        label: 'User List',
        id: 'user_list',
        value: '301',
        children: [
          {
            label: 'User List View',
            value: '30101',
            checked: false,
          },
          {
            label: 'User Add',
            value: '30102',
            checked: false,
          },
          {
            label: 'User Detail View',
            value: '30103',
            checked: false,
          },
          {
            label: 'User Edit',
            value: '30104',
            checked: false,
          },
        ],
      },
      {
        label: 'Roles List',
        id: 'roles_list',
        value: '302',
        children: [
          {
            label: 'Role List View',
            value: '30201',
            checked: false,
          },
          {
            label: 'Role Add',
            value: '30202',
            checked: false,
          },
          {
            label: 'Role Detail View',
            value: '30203',
            checked: false,
          },
          {
            label: 'Role Edit',
            value: '30204',
            checked: false,
          },
        ],
      },
    ],
  }, {
    label: 'Purchase',
    id: 'purchase',
    value: '4',
    children: [
      {
        label: 'Purchase List',
        id: 'purchase_list',
        value: '401',
        children: [
          {
            label: 'Purchase List View',
            value: '40101',
            checked: false,
          },
          {
            label: 'Purchase Add',
            value: '40102',
            checked: false,
          },
          {
            label: 'Purchase Detail View',
            value: '40103',
            checked: false,
          },
          {
            label: 'Purchase Update',
            value: '40104',
            checked: false,
          },
          {
            label: 'Purchase Approve',
            value: '40105',
            checked: false,
          },
          {
            label: 'Purchase Checking',
            value: '40106',
            checked: false,
          },
          {
            label: 'Purchase Print',
            value: '40107',
            checked: false,
          }
        ],
      },
    ],
  }, {
    label: 'Inward',
    id: 'inward',
    value: '5',
    children: [
      {
        label: 'Inward List',
        id: 'inward_list',
        value: '501',
        children: [
          {
            label: 'Inward List View',
            value: '50101',
            checked: false,
          },
          {
            label: 'Inward Add',
            value: '50102',
            checked: false,
          },
          {
            label: 'Inward Detail View',
            value: '50103',
            checked: false,
          },
          {
            label: 'Inward Upadate',
            value: '50104',
            checked: false,
          },
          {
            label: 'Inward Print',
            value: '50105',
            checked: false,
          }
        ],
      },
    ],
  }, {
    label: 'Outward',
    id: 'outward',
    value: '6',
    children: [
      {
        label: 'Outward List',
        id: 'outward_list',
        value: '601',
        children: [
          {
            label: 'Outward List View',
            value: '60101',
            checked: false,
          },
          {
            label: 'Outward Add',
            value: '60102',
            checked: false,
          },
          {
            label: 'Outward Detail View',
            value: '60103',
            checked: false,
          },
          {
            label: 'Outward Upadate',
            value: '60104',
            checked: false,
          },
          {
            label: 'Outward Print',
            value: '60105',
            checked: false,
          }
        ],
      },
    ],
  }, {
    label: 'Jobs',
    id: 'jobs',
    value: '7',
    children: [
      {
        label: 'Jobs List',
        id: 'jobs_list',
        value: '701',
        children: [
          {
            label: 'Job List View',
            value: '70101',
            checked: false,
          },
          {
            label: 'Job Add',
            value: '70102',
            checked: false,
          },
          {
            label: 'Job Detail View',
            value: '70103',
            checked: false,
          },
          {
            label: 'Job Upadate',
            value: '70104',
            checked: false,
          },
          {
            label: 'Job Print',
            value: '70105',
            checked: false,
          }
        ],
      },
    ],
  }, {
    label: 'Feedbacks',
    id: 'feedbacks',
    value: '8',
    children: [
      {
        label: 'Feedback List',
        id: 'feedback_list',
        value: '801',
        children: [
          {
            label: 'Feedback List View',
            value: '80101',
            checked: false,
          },
        ],
      },
    ],
  },])


  const permissionChangeHandle = (val, e) => {
    let permissionArray = [];
    if (!e.target.checked) {
      for (let i = 0; resources.length > i; i++) {
        if (resources[i] == val) {
        } else {
          permissionArray.push(resources[i]);
        }
      }
      setResources(permissionArray);
    } else {
      permissionArray = resources.slice();
      if (resources.includes(val)) {
      } else {
        permissionArray.push(val);
      }
      setResources(permissionArray);
    }
  };

  const getResources = () => {

  };






  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("resources", JSON.stringify(resources));
    axios("Users/add_role", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/roles",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  };

  useEffect(() => {
    getResources();
  }, []);
  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} name="name" className="form-control" />
          </div>
          <div class="container">

            <div id="accordion">
              {data.map((value, index) => {
                return (
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0 d-inline">
                        <a class="btn btn-link" data-toggle="collapse" data-target={"#" + value.id} aria-expanded="true" aria-controls="collapseOne">
                          {value.label}
                        </a>
                      </h5>
                    </div>
                    {value.children.map((row, i) => {
                      return (
                        <div id={value.id} class="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                          <div class="card-body" >
                            <div class="card">
                              <div class="card-header">
                                <a href="#" data-toggle="collapse" data-target={"#" + row.id}>
                                  {row.label}
                                </a>
                              </div>
                              {row.children.map((items, j) => {
                                return (
                                  <div class="card-body collapse" id={row.id}>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => permissionChangeHandle(items.value, e)}
                                      checked={resources.includes(items.value)}
                                      value={items.value}
                                    ></input>
                                    <label>{items.label}</label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
          <div class="form-group">
            <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div >
  )
}

export default AddRole