
import React, { useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "react-dates";
import IsAllowed from "../../Utils/IsAllowed";
import { BASE_URL } from '../../Constants/constants';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function Inward() {
  const history = useHistory();
  const today = moment();
  const classes = useStyles();
  const [inward, setInward] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);


  const [departments, setDepartments] = useState([]);

  const [department, setDepartment] = useState(0);



  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const alert = useAlert();



  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }



  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    inward_list();
  }, [page, per_page, search, endDate, startDate, department]);
  const viewDetails = (id) => {
    history.push(`/edit-inward/${id}`);
  };


  const inward_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Inward/inward_list?department=${department}&page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setLoading(false);
          setInward(res.data.data);
          setTotal(res.data.total);
        } else {
          setLoading(false);
          alert.show(res.error);
        }
      })
      .catch((err) => console.log(err));
  };



  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };




  const printPage = (jobId) => {
    let formdata = new FormData();
    formdata.append("id", jobId);
    axios(`Jobs/print_inward_gate_pass`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          // alert.success(response.data.success);
          // getJobDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">All Inward</h1>
        <div className="col-md-12 text-right">
          {IsAllowed(["50102"]) && (
            <Link className="btn btn-primary " to="/add-inward">
              ADD INWARD
            </Link>
          )}
        </div>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>


              <div className="col-md-4">
                <select
                  className="form-control"
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  <option value="0">Select Department </option>
                  {departments &&
                    departments.map((val, index) => {
                      return (
                        <option key={index} value={val.value}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
                {/* <SelectSearch options={departments} onChange={setDepartment} value={department} id="department" name="department" search placeholder="Choose your department" /> */}
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner={<RingLoader loading={loading} color="#36d7b7" />}
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Inward ID</th>
                        <th>Delivered by </th>
                        <th>Stored at</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {inward.length > 0 ? inward.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.inward_no}</td>
                            <td>{value.delivered_by}</td>
                            <td>{value.stored_at}</td>
                            <td>{value.created_at}</td>
                            <td className="space-evenly">
                              {IsAllowed(["50103"]) && (
                                <a>
                                  <button className="btn btn-sm btn-info" onClick={() => viewDetails(value.id)}><i className="fa fa-edit"></i></button>
                                </a>
                              )}
                              {IsAllowed(["50105"]) && (
                                <a
                                  target="_blank"
                                  href={
                                    BASE_URL + "Utils/print_inward_pass/" +
                                    value.id +
                                    "/1"
                                  }
                                >
                                  <button className="btn btn-primary ml-2 btn-sm">
                                    <i className="fa fa-sm fa-print"></i>
                                  </button>
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      }) : (<td colSpan={8} className="text-center"> No Inward</td>)}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Inward;
