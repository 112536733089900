import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import IsAllowed from "../../Utils/IsAllowed";
import "./Purchase.css"

function EditPurchase(props) {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [purchaseId, setPurchaseId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [purchase, setPurchase] = useState({});
  const [purposeList, setPurposeList] = useState([{ value: 'For Sales', name: 'For Sales' }, { value: 'for Stock', name: 'for Stock' }, { value: 'For Gift', name: 'For Gift' }]);
  const [purchaseItems, setPurchaseItems] = useState([]);

  let handleChange = (i, e) => {
    let newFormValues = [...purchaseItems];
    newFormValues[i][e.target.name] = e.target.value;
    setPurchaseItems(newFormValues);
  }


  const getPurchaseDetails = () => {
    setLoading(true);
    axios("Purchase/purchase_detail?purchase_id=" + purchaseId, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setPurchase(response.data.purchase);
          setPurchaseItems(response.data.purchase_items);
          setLoading(false);
        } else {
          setLoading(false);
          alert.show(response.data.error);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        // history.push({
        //   pathname: "/leads",
        //   state: { data: true },
        // });
      });
  };




  const handleChangePurchase = (field, e) => {
    setPurchase({ ...purchase, [field]: e })
  }


  const handleClickSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(purchaseItems));
    formdata.append("purchase_id", purchaseId);
    formdata.append("department_id", purchase.department_id);
    formdata.append("purpose", purchase.purpose);
    formdata.append("order_no", purchase.order_no);
    formdata.append("require_date", purchase.require_date);

    axios("Purchase/update_purchase", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          // history.push({
          //   pathname: "/jobs",
          //   state: { data: true },
          // });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  }

  const handleClickApprove = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", purchaseId);
    axios(`Purchase/make_approve`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getPurchaseDetails()
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const handleClickCheck = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", purchaseId);
    axios(`Purchase/make_checked`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getPurchaseDetails()
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };




  useEffect(() => {
    getPurchaseDetails();
    getDepartments();
  }, []);


  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="row">
            <div class="col-md-4 form-group">
              <label for="purchase_id">Purchase Id</label>
              <input type="text" id="purchase_id" readOnly name="purchase_id" class="form-control" value={purchase.purchase_no} />
            </div>
            <div class="col-md-4 form-group">
              <label for="require_date">Required Date</label>
              <input type="date" id="require_date" name="require_date" onChange={(e) => handleChangePurchase('require_date', e.target.value)} class="form-control" value={purchase.require_date} />
            </div>
            <div class="col-md-4 form-group">
              <label for="order_no">Order No</label>
              <input type="text" id="order_no" readOnly={purchase.approved_by > 0 ? false : true} name="order_no" onChange={(e) => handleChangePurchase('order_no', e.target.value)} class="form-control" value={purchase.order_no} />
            </div>
            <div class="col-md-6 form-group">
              <label for="department">Department</label>
              <SelectSearch options={departments} value={purchase.department_id} disabled id="department" name="department" search placeholder="Choose your department" />
            </div>
            <div class="col-md-6 form-group">
              <label for="purpose">Purpose of Purchase</label>
              <SelectSearch options={purposeList} onChange={(e) => handleChangePurchase('purpose', e)} value={purchase.purpose} id="purpose" name="purpose" search placeholder="Select your Purpose" />
            </div>



          </div>

          {purchaseItems.map((element, index) => (
            <div class="row main-box">
              <div class="col-md-8">
                <div class="row ">
                  <div class="col-md-10 form-group">
                    <label for="productId">Select Product</label>
                    <input type="text" readOnly name="productName" class="form-control" value={element.product_name} />
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="productQty">Quantity</label>
                    <input type="number" id="productQty" name="productQty" class="form-control " onChange={e => handleChange(index, e)} value={element.qty} />
                  </div>
                  <div class="col-md-8 form-group">
                    <label for="source_of_supply">Source Of Supply</label>
                    <textarea id="source_of_supply" name="source_of_supply" class="form-control" rows="1" onChange={e => handleChange(index, e)} value={element.source_of_supply} >{element.source_of_supply}</textarea>
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="item_cost">Item Cost</label>
                    <input type="text" id="item_cost" name="item_cost" class="form-control  " onChange={e => handleChange(index, e)} value={element.item_cost} />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row itembox-p">
                  <div class="col-md-12 form-group">
                    <label for="remarks">Remarks</label>
                    <textarea id="remarks" name="remarks" class="form-control" rows="3" onChange={e => handleChange(index, e)} value={element.remarks} >{element.remarks}</textarea>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div class="  text-center"><hr></hr>
            {IsAllowed(["40104"]) &&
              <button type="button" class="btn btn-danger mr-2" onClick={(e) => handleClickSubmit(e)}>Update</button>
            }
            {/* {IsAllowed(["40105"]) && (purchase.approved_by == 0 ?
              <button type="button" class="btn btn-success mr-2 ml-2" onClick={(e) => handleClickApprove(e)}>Approve</button> : <button type="button" class="btn btn-success mr-2 ml-2" >Approved</button>
            )} */}
            {IsAllowed(["40106"]) ?
              (
                purchase.checked_by == 0 ?
                  <button type="button" class="btn btn-info" onClick={(e) => handleClickCheck(e)}>Check</button> : <button type="button" class="btn btn-info" >Checked</button>
              ) : ''}
          </div>



        </form>


      </LoadingOverlay>
    </div>

  )
}

export default EditPurchase